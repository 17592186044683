import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  LessorAgreementConfiguration,
  LessorSearchParams,
  UpdateLessorAggreementConfiguration,
  UpdateLessorResponse,
} from "api/leasing/agreementConfigurations.types";
import getApiUrl from "modules/api/getApiUrl";
import { RootState } from "store";
import { currentUserTokenSelector } from "../../../currentUser/selectors";

const CACHE_KEY_LESSOR_CONFIGURATION = "LessorConfiguration";

export const lessorConfigurationApi = createApi({
  reducerPath: "lessorConfigurationApi",

  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(""),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),

  tagTypes: [CACHE_KEY_LESSOR_CONFIGURATION],

  endpoints: (builder) => ({
    getLessorConfiguration: builder.query<
      LessorAgreementConfiguration,
      LessorSearchParams
    >({
      query: ({ lessorRef, lessorType }) =>
        `/leasing/lessors-configurations?lessorRef=${lessorRef}&lessorType=${lessorType}`,
      providesTags: (_result, _error, { lessorRef }) => [
        { type: CACHE_KEY_LESSOR_CONFIGURATION, id: lessorRef },
      ],
    }),

    upsertLessorConfiguration: builder.mutation<
      UpdateLessorResponse,
      UpdateLessorAggreementConfiguration
    >({
      query: ({ lessorRef, lessorType, configuration }) => ({
        method: "POST",
        url: `/leasing/lessors-configurations?lessorRef=${lessorRef}&lessorType=${lessorType}`,
        body: configuration,
      }),
      invalidatesTags: [CACHE_KEY_LESSOR_CONFIGURATION],
    }),
  }),
});

export const {
  useGetLessorConfigurationQuery,
  useUpsertLessorConfigurationMutation,
} = lessorConfigurationApi;
