import { combineEpics } from "redux-observable";

// Epics
import createEpic from "./epics/createEpic";
import fetchAllEpic from "./epics/fetchAllEpic";
import fetchByIdEpic from "./epics/fetchByIdEpic";
import updateEpic from "./epics/updateEpic";

const epic = combineEpics(createEpic, fetchAllEpic, fetchByIdEpic, updateEpic);

export default epic;
