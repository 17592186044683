import { EntityId } from "@reduxjs/toolkit";
import { generatePath } from "react-router-dom";

// Constants
import { ROUTES_NAME, ROUTES_PATH } from "modules/routing/constants";

const getEntityRoutePath = (id: EntityId, routeName: ROUTES_NAME) =>
  generatePath(ROUTES_PATH[routeName], { id });

export default getEntityRoutePath;
