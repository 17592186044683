import { ActionsObservable, Epic } from "redux-observable";
import { push } from "connected-react-router";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as collectorTypesApi from "api/collectorTypes/collectorTypesApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { collectorTypesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import CollectorType from "types/entities/collectorType";

// Utils
import getEntityRoutePath from "modules/routing/getEntityRoutePath";

const updateSuccessHandler = ({ data: entity }: FetchResponse<CollectorType>) =>
  of(
    collectorTypesSlice.actions.updateSuccess({
      entity,
    }),
    push(getEntityRoutePath(entity.id, ROUTES_NAME.COLLECTOR_TYPES_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "Le type de collecteur a été mis à jour avec succès.",
    })
  );

const updateErrorHandler = (error: Error) =>
  of(
    collectorTypesSlice.actions.updateError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la mise à jour du type de collecteur.",
    })
  );

const updateEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof collectorTypesSlice.actions.update>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(collectorTypesSlice.actions.update.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        collectorTypesApi.update({ entity: payload.entity, token })
      ).pipe(
        switchMap(updateSuccessHandler),
        catchError(updateErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default updateEpic;
