import { createSelector } from "@reduxjs/toolkit";

// Selectors
import { usersSelectors } from "store/features/users/selectors";

// Store
import { RootState } from "store";

export const currentUserStateSelector = (state: RootState) => state.currentUser;

export const currentUserIdSelector = createSelector(
  currentUserStateSelector,
  ({ id }) => id
);

export const currentUserNameSelector = createSelector(
  currentUserStateSelector,
  ({ name }) => name
);

export const currentUserEntitySelector = createSelector(
  currentUserIdSelector,
  usersSelectors.selectEntities,
  (currentUserId, usersEntities) => {
    if (currentUserId) {
      return usersEntities[currentUserId];
    }
  }
);

export const currentUserRefreshTokenSelector = createSelector(
  currentUserStateSelector,
  ({ refreshToken }) => refreshToken
);

export const currentUserTokenSelector = createSelector(
  currentUserStateSelector,
  ({ token }) => token
);

export const isLoginPendingSelector = createSelector(
  currentUserStateSelector,
  ({ loginStatus }) => loginStatus === "PENDING"
);

export const isRefreshTokenSuccessSelector = createSelector(
  currentUserStateSelector,
  ({ refreshTokenStatus }) => refreshTokenStatus === "SUCCESS"
);
