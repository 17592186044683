import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import BsNav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Utils
import getRoutePath from "modules/routing/getRoutePath";

const Nav: FC = () => (
  <BsNav className="mr-auto">
    <BsNav.Link as={NavLink} title="Home" to={getRoutePath(ROUTES_NAME.HOME)}>
      Home
    </BsNav.Link>
    <NavDropdown id="nav-dropdown-fournisseurs" title="Fournisseurs">
      <NavDropdown.Item
        as={NavLink}
        title="Fournisseurs"
        to={getRoutePath(ROUTES_NAME.TECH_PROVIDERS)}
      >
        Fournisseurs
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        title="Partenaires"
        to={getRoutePath(ROUTES_NAME.PARTNERS)}
      >
        Partenaires
      </NavDropdown.Item>
    </NavDropdown>
    <BsNav.Link
      as={NavLink}
      title="Clients"
      to={getRoutePath(ROUTES_NAME.CLIENT_ACCESSES)}
    >
      Clients
    </BsNav.Link>
    <NavDropdown id="nav-dropdown-sites" title="Déploiements">
      <NavDropdown.Item
        as={NavLink}
        title="Sites"
        to={getRoutePath(ROUTES_NAME.SITES)}
      >
        Sites
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        title="Réseaux"
        to={getRoutePath(ROUTES_NAME.SITE_NETWORKS)}
      >
        Réseaux
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        title="Points de collecte"
        to={getRoutePath(ROUTES_NAME.POINTS_OF_SALES)}
      >
        Points de collecte
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        title="Types de collecteur"
        to={getRoutePath(ROUTES_NAME.COLLECTOR_TYPES)}
      >
        Types de collecteur
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        title="Machines"
        to={getRoutePath(ROUTES_NAME.COLLECTORS)}
      >
        Machines
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        title="Tablettes de caisse"
        to={getRoutePath(ROUTES_NAME.POS_MACHINES)}
      >
        Tablettes de caisse
      </NavDropdown.Item>
    </NavDropdown>
    <NavDropdown id="nav-dropdown-contenants" title="Contenants">
      <NavDropdown.Item
        as={NavLink}
        title="Contenants"
        to={getRoutePath(ROUTES_NAME.ITEMS)}
      >
        Contenants
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        title="Types de contenants"
        to={getRoutePath(ROUTES_NAME.ITEM_TYPES)}
      >
        Types de contenants
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        title="Catégories de contenants"
        to={getRoutePath(ROUTES_NAME.ITEM_CATEGORIES)}
      >
        Catégories de contenants
      </NavDropdown.Item>
    </NavDropdown>
    <NavDropdown id="nav-dropdown-catering" title="Restauration">
      <NavDropdown.Item
        as={NavLink}
        title="Restaurateurs"
        to={getRoutePath(ROUTES_NAME.RESTAURATEURS)}
      >
        Restaurateurs
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        title="Restaurants"
        to={getRoutePath(ROUTES_NAME.RESTAURANTS)}
      >
        Restaurants
      </NavDropdown.Item>
    </NavDropdown>
    <NavDropdown id="nav-dropdown-catering" title="Utilisateurs">
      <NavDropdown.Item
        as={NavLink}
        title="Utilisateurs"
        to={getRoutePath(ROUTES_NAME.USERS)}
      >
        Tous les utilisateurs
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        title="Utilisateurs"
        to={getRoutePath(ROUTES_NAME.USER_RECREDIT)}
      >
        Recréditer
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item
        as={NavLink}
        title="Utilisateurs"
        to={getRoutePath(ROUTES_NAME.RESET_PASSWORD)}
      >
        Réinitialiser un mot de passe
      </NavDropdown.Item>
    </NavDropdown>
  </BsNav>
);

export default Nav;
