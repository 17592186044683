import { combineEpics } from "redux-observable";

// Epics
import createEpic from "./epics/createEpic";
import fetchAllEpic from "./epics/fetchAllEpic";
import fetchByIdEpic from "./epics/fetchByIdEpic";
import updateEpic from "./epics/updateEpic";
import duplicateEpic from "./epics/duplicateEpic";
import searchEpic from "./epics/searchEpic";

const epic = combineEpics(
  createEpic,
  fetchAllEpic,
  fetchByIdEpic,
  updateEpic,
  duplicateEpic,
  searchEpic
);

export default epic;
