import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as itemsApi from "api/items/itemsApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { itemsSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import Item from "types/entities/item";

const fetchAllSuccessHandler = ({
  data: { count, totalCount, items, entities },
}: FetchResponse<{
  count: number;
  totalCount?: number;
  items: Item[];
  entities?: Item[];
}>) =>
  of(
    itemsSlice.actions.fetchAllSuccess({
      count: totalCount !== undefined ? totalCount : count,
      entities: entities !== undefined ? entities : items,
    })
  );

const fetchAllErrorHandler = (error: Error) =>
  of(
    itemsSlice.actions.fetchAllError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la récupération des contenants.",
    })
  );

const fetchAllEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof itemsSlice.actions.fetchAll>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(itemsSlice.actions.fetchAll.type)),
    switchMap(() => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(itemsApi.fetchAll({ token })).pipe(
        switchMap(fetchAllSuccessHandler),
        catchError(fetchAllErrorHandler),
        startWith(
          notificationSlice.actions.clean(),
          itemsSlice.actions.cleanSearch()
        )
      );
    })
  );

export default fetchAllEpic;
