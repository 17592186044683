import { EntityId } from "@reduxjs/toolkit";
// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";
// Types
import {
  BoxesV3Configurations,
  BoxesV3ConfigurationsCreateApiModel,
  BoxesV3ConfigurationsUpdateApiModel,
  CreateResponseAPI,
  UpdateResponse,
} from "types/entities/boxesV3Configurations";
// Constants
import { API_ENDPOINT } from "./constants";

const baseUrl = getApiUrl(API_ENDPOINT);

export const create = (params: {
  entity: BoxesV3ConfigurationsCreateApiModel;
  token: string;
}) =>
  fetch<CreateResponseAPI>(`${baseUrl}/`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });

export const fetchAll = (params: { token: string }) =>
  fetch<BoxesV3Configurations[]>(baseUrl, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { boxId: EntityId; token: string }) =>
  fetch<BoxesV3Configurations>(`${baseUrl}/${params.boxId}`, {
    method: "GET",
    token: params.token,
  });

export const update = (params: {
  entity: BoxesV3ConfigurationsUpdateApiModel;
  token: string;
}) => {
  return fetch<UpdateResponse>(`${baseUrl}/${params.entity.boxId}`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });
};
