import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as sitesApi from "api/sites/sitesApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { sitesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import Site from "types/entities/site";
import { push } from "connected-react-router";
import getEntityRoutePath from "modules/routing/getEntityRoutePath";
import { ROUTES_NAME } from "modules/routing/constants";

const duplicateSuccessHandler = ({ data: entity }: FetchResponse<Site>) =>
  of(
    sitesSlice.actions.duplicateSuccess({
      entity,
    }),
    sitesSlice.actions.fetchById({ id: entity.id }),
    push(getEntityRoutePath(entity.id, ROUTES_NAME.SITES_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "Le site a été dupliquée avec succès",
    })
  );

const duplicateErrorHandler = (error: Error) =>
  of(
    sitesSlice.actions.duplicateError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message: "Une erreur s'est produite lors de la duplication du site.",
    })
  );

const duplicateEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof sitesSlice.actions.duplicate>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(sitesSlice.actions.duplicate.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(sitesApi.duplicate({ id: payload.id, token })).pipe(
        switchMap(duplicateSuccessHandler),
        catchError(duplicateErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default duplicateEpic;
