import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as servicesApi from "api/services/servicesApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { servicesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import Service from "types/entities/service";

const fetchAllSuccessHandler = ({ data: entities }: FetchResponse<Service[]>) =>
  of(
    servicesSlice.actions.fetchAllSuccess({
      entities,
    })
  );

const fetchAllErrorHandler = (error: Error) =>
  of(
    servicesSlice.actions.fetchAllError({
      error: error.message,
    })
  );

const fetchAllEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof servicesSlice.actions.fetchAll>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(servicesSlice.actions.fetchAll.type)),
    switchMap(() => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(servicesApi.fetchAll({ token })).pipe(
        switchMap(fetchAllSuccessHandler),
        catchError(fetchAllErrorHandler)
      );
    })
  );

export default fetchAllEpic;
