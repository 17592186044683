import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as boxesV3ConfigurationsApi from "api/boxesV3Configurations/boxesV3Configurations.api";

// Constants

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";

// Types
import { FetchResponse } from "modules/api/types";

// Utils
import { boxesV3ConfigurationsSlice } from "../slice";
import { UpdateResponse } from "types/entities/boxesV3Configurations";

const updateSuccessHandler = ({
  data: entity,
}: FetchResponse<UpdateResponse>) => of();

const updateErrorHandler = (error: Error) =>
  of(
    boxesV3ConfigurationsSlice.actions.updateError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la mise à jour du collecteur.",
    })
  );

const updateEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof boxesV3ConfigurationsSlice.actions.update>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(boxesV3ConfigurationsSlice.actions.update.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;
      return from(
        boxesV3ConfigurationsApi.update({ entity: payload.entity, token })
      ).pipe(
        switchMap(updateSuccessHandler),
        catchError(updateErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default updateEpic;
