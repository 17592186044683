// Types
import {
  EnrichedErrorResponse,
  ErrorResponse,
  SimpleErrorResponse,
} from "./types";

const isEnrichedErrorResponse = (
  response?: ErrorResponse
): response is EnrichedErrorResponse => {
  return (response as EnrichedErrorResponse).code !== undefined;
};

const isSimpleErrorResponse = (
  response?: ErrorResponse
): response is SimpleErrorResponse => {
  return (response as SimpleErrorResponse).data !== undefined;
};

const isErrorResponse = (res: object): res is ErrorResponse => {
  return (
    res.hasOwnProperty("statusCode") &&
    res.hasOwnProperty("error") &&
    res.hasOwnProperty("message")
  );
};

const getErrorMessage = (response: ErrorResponse, requestId?: string) => {
  if (typeof response !== "object" || response === null) {
    return;
  }

  if (isEnrichedErrorResponse(response)) {
    return response.error;
  }

  if (isSimpleErrorResponse(response)) {
    return response.data;
  }

  if (isErrorResponse(response)) {
    return `${response.message}. ${
      requestId !== undefined ? `<b>requestId: ${requestId}</b>` : ``
    }`;
  }
};

export default getErrorMessage;
