import { ActionsObservable, Epic } from "redux-observable";
import { push } from "connected-react-router";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as techProvidersApi from "api/techProviders/techProvidersApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { techProvidersSlice } from "../slice";

// Types
import { Credentials } from "api/types";
import { FetchResponse } from "modules/api/types";
import TechProvider from "types/entities/techProvider";

// Utils
import getCredentialsHTML from "modules/entity/getCredentialsHTML";
import getEntityRoutePath from "modules/routing/getEntityRoutePath";

const createSuccessHandler = ({
  data: { credentials, tech_provider },
}: FetchResponse<{
  credentials: Credentials;
  tech_provider: TechProvider;
}>) =>
  of(
    techProvidersSlice.actions.createSuccess({
      entity: tech_provider,
    }),
    push(getEntityRoutePath(tech_provider.id, ROUTES_NAME.TECH_PROVIDERS_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: `Voici les identifiants de connexion à communiquer au fournisseur : ${getCredentialsHTML(
        credentials.client_id,
        credentials.client_secret
      )}`,
      title: "Le fournisseur a été créé avec succès.",
    })
  );

const createErrorHandler = (error: Error) =>
  of(
    techProvidersSlice.actions.createError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message: "Une erreur s'est produite lors de la création du fournisseur.",
    })
  );

const createEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof techProvidersSlice.actions.create>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(techProvidersSlice.actions.create.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        techProvidersApi.create({ entity: payload.entity, token })
      ).pipe(
        switchMap(createSuccessHandler),
        catchError(createErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default createEpic;
