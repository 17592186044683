// Types

import { InitialState } from "./types";

export const SLICE_NAME = "recreditUsers";

export const INITIAL_STATE: InitialState = {
  boxId: 0,
  cashSystemIdentifier: "",
  items: [],
  status: "INITIAL",
  error: null,
};
