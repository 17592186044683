import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import ItemType from "types/entities/itemType";

// Utils
import { createBaseReducers } from "store/utils/reducers";

export const itemTypesAdapter = createEntityAdapter<ItemType>();

export const itemTypesSlice = createSlice({
  name: SLICE_NAME,
  initialState: itemTypesAdapter.getInitialState(INITIAL_STATE),
  reducers: createBaseReducers<ItemType>(itemTypesAdapter),
});

export default itemTypesSlice.reducer;
