import { push } from "connected-react-router";
import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as itemsApi from "api/items/itemsApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { itemsSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import Item from "types/entities/item";

// Utils
import getEntityRoutePath from "modules/routing/getEntityRoutePath";

const updateSuccessHandler = ({ data: entity }: FetchResponse<Item>) =>
  of(
    itemsSlice.actions.setOne({
      entity,
    }),
    push(getEntityRoutePath(entity.id, ROUTES_NAME.ITEMS_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "Le contenant a été mis à jour avec succès.",
    })
  );

const updateErrorHandler = (error: Error) =>
  of(
    itemsSlice.actions.updateError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message: "Une erreur s'est produite lors de la mise à jour du contenant.",
    })
  );

const updateEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof itemsSlice.actions.update>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(itemsSlice.actions.update.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;
      const { id, ItemIdentifierFormatId, ItemTypeId, code, secret } =
        payload.entity;
      return from(
        itemsApi.update({
          entity: { id, ItemIdentifierFormatId, ItemTypeId, code, secret },
          token,
        })
      ).pipe(
        switchMap(updateSuccessHandler),
        catchError(updateErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default updateEpic;
