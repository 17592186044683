import { combineEpics } from "redux-observable";
// Epics
import createEpic from "./epics/createEpic";
import emptyEpic from "./epics/emptyEpic";
import fetchAllEpic from "./epics/fetchAllEpic";
import fetchByIdEpic from "./epics/fetchByIdEpic";
import searchEpic from "./epics/searchEpic";
import updateEpic from "./epics/updateEpic";

const epic = combineEpics(
  createEpic,
  emptyEpic,
  fetchAllEpic,
  fetchByIdEpic,
  updateEpic,
  searchEpic
);

export default epic;
