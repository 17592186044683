import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PaymentSystemConfiguration } from "api/payment/types/payment-system-configuration";
import {
  PayeeSearchParams,
  UpdatePaymentSystemConfiguration,
} from "api/payment/types/payment-system-configuration-queries";
import { PaymentSystemName } from "api/payment/types/payment-system-name";
import getApiUrl from "modules/api/getApiUrl";
import { RootState } from "store";
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

const CACHE_KEY_PAYMENT_SYSTEM_CONFIGURATION = "PaymentSystemConfiguration";

export const paymentSytemConfigurationApi = createApi({
  reducerPath: "paymentSytemConfigurationApi",

  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(""),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),

  tagTypes: [CACHE_KEY_PAYMENT_SYSTEM_CONFIGURATION],

  endpoints: (builder) => ({
    getPaymentSystemConfiguration: builder.query<
      PaymentSystemConfiguration<PaymentSystemName>,
      PayeeSearchParams
    >({
      query: ({ payeeRef, payeeType }) =>
        `/admin/payment-contexts/payment-system-configuration?payeeRef=${payeeRef}&payeeType=${payeeType}`,
      providesTags: (_result, _error, { payeeRef }) => [
        { type: CACHE_KEY_PAYMENT_SYSTEM_CONFIGURATION, id: payeeRef },
      ],
    }),

    upsertPaymentSystemConfiguration: builder.mutation<
      PaymentSystemConfiguration<PaymentSystemName>,
      UpdatePaymentSystemConfiguration
    >({
      query: ({ payeeRef, payeeType, configuration }) => ({
        method: "POST",
        url: `/admin/payment-contexts/payment-system-configuration?payeeRef=${payeeRef}&payeeType=${payeeType}`,
        body: configuration,
      }),
      invalidatesTags: [CACHE_KEY_PAYMENT_SYSTEM_CONFIGURATION],
    }),
  }),
});

export const {
  useGetPaymentSystemConfigurationQuery,
  useUpsertPaymentSystemConfigurationMutation,
} = paymentSytemConfigurationApi;
