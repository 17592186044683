import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import { RestaurantNetworks } from "types/entities/restaurantNetworks";
import { createBaseReducers } from "store/utils/reducers";

// Utils

export const restaurantNetworksAdapter =
  createEntityAdapter<RestaurantNetworks>();

export const restaurantNetworksSlice = createSlice({
  name: SLICE_NAME,
  initialState: restaurantNetworksAdapter.getInitialState(INITIAL_STATE),
  reducers: createBaseReducers<RestaurantNetworks>(restaurantNetworksAdapter),
});

export default restaurantNetworksSlice.reducer;
