import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

// Constants
import { DEFAULT_ROUTE_NAME } from "modules/routing/constants";

// Hooks
import useAuth from "modules/auth/useAuth";

// Types
import { RouteData } from "modules/routing/types";

// Utils
import getRoutePath from "modules/routing/getRoutePath";

type Props = RouteProps & RouteData;

const PublicRoute: FC<Props> = (props) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated && props.isRestricted) {
    return <Redirect to={getRoutePath(DEFAULT_ROUTE_NAME)} />;
  } else {
    return <Route {...props} />;
  }
};

export default PublicRoute;
