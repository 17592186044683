import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as boxesV3ConfigurationsApi from "api/boxesV3Configurations/boxesV3Configurations.api";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";

// Types
import { FetchResponse } from "modules/api/types";

// Utils
import { CreateResponseAPI } from "types/entities/boxesV3Configurations";
import { boxesV3ConfigurationsSlice } from "../slice";

const createSuccessHandler = ({
  data: entity,
}: FetchResponse<CreateResponseAPI>) => of();

const createErrorHandler = (error: Error) =>
  of(
    boxesV3ConfigurationsSlice.actions.createError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message: "Une erreur s'est produite lors de la création du collecteur.",
    })
  );

const createEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof boxesV3ConfigurationsSlice.actions.create>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(boxesV3ConfigurationsSlice.actions.create.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;
      return from(
        boxesV3ConfigurationsApi.create({ entity: payload.entity, token })
      ).pipe(
        switchMap(createSuccessHandler),
        catchError(createErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default createEpic;
