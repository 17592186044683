import storage from "redux-persist/lib/storage";

// Constants
import { SLICE_NAME as CURRENT_USER_SLICE_NAME } from "./features/currentUser/constants";

export const PERSIST_CONFIG = {
  key: "state",
  storage,
  whitelist: [CURRENT_USER_SLICE_NAME],
};
