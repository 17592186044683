import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as collectorTypeApi from "api/collectorTypes/collectorTypesApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { collectorTypesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import CollectorType from "types/entities/collectorType";
import { push } from "connected-react-router";
import getEntityRoutePath from "modules/routing/getEntityRoutePath";
import { ROUTES_NAME } from "modules/routing/constants";

const duplicateSuccessHandler = ({
  data: entity,
}: FetchResponse<CollectorType>) =>
  of(
    collectorTypesSlice.actions.duplicateSuccess({
      entity,
    }),
    push(getEntityRoutePath(entity.id, ROUTES_NAME.COLLECTORS_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "La machine a été dupliquée avec succès",
    })
  );

const duplicateErrorHandler = (error: Error) =>
  of(
    collectorTypesSlice.actions.duplicateError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la duplication de la machine.",
    })
  );

const duplicateEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof collectorTypesSlice.actions.duplicate>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(collectorTypesSlice.actions.duplicate.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(collectorTypeApi.duplicate({ id: payload.id, token })).pipe(
        switchMap(duplicateSuccessHandler),
        catchError(duplicateErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default duplicateEpic;
