import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import ClientAccess from "types/entities/clientAccess";

// Utils
import { createBaseReducers } from "store/utils/reducers";

export const clientAccessesAdapter = createEntityAdapter<ClientAccess>();

export const clientAccessesSlice = createSlice({
  name: SLICE_NAME,
  initialState: clientAccessesAdapter.getInitialState(INITIAL_STATE),
  reducers: createBaseReducers<ClientAccess>(clientAccessesAdapter),
});

export default clientAccessesSlice.reducer;
