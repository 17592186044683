import { combineEpics } from "redux-observable";

// Epics
import fetchAllEpic from "./epics/fetchAllEpic";
import fetchByIdEpic from "./epics/fetchByIdEpic";
import createEpic from "./epics/createEpic";
import updateEpic from "./epics/updateEpic";

const epic = combineEpics(fetchAllEpic, fetchByIdEpic, createEpic, updateEpic);

export default epic;
