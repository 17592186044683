import { EntityId } from "@reduxjs/toolkit";

// Constants
import { API_ENDPOINT } from "./constants";

// Types
import ItemTypeCategory from "types/entities/itemTypeCategory";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";
import { FetchResponse } from "modules/api/types";

const baseUrl = getApiUrl(API_ENDPOINT);

export const fetchAll = (params: { token: string }) =>
  fetch<ItemTypeCategory[]>(baseUrl, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { id: EntityId; token: string }) =>
  fetch<ItemTypeCategory>(`${baseUrl}/${params.id}`, {
    method: "GET",
    token: params.token,
  });

export const create = async (params: {
  entity: ItemTypeCategory;
  token: string;
}) => {
  const fetchCreate = await fetch<ItemTypeCategory>(`${baseUrl}`, {
    method: "POST",
    body: JSON.stringify(params.entity),
    token: params.token,
  });

  const promise = new Promise<FetchResponse<ItemTypeCategory>>(
    (resolve, reject) => {
      resolve({
        data: {
          id: fetchCreate.data.id,
          internalName: params.entity.internalName,
          friendlyName: params.entity.friendlyName,
        },
        headers: fetchCreate.headers,
      });
    }
  );

  return promise;
};

export const update = (params: { entity: ItemTypeCategory; token: string }) =>
  fetch<ItemTypeCategory>(`${baseUrl}/${params.entity.id}`, {
    method: "POST",
    body: JSON.stringify({
      internalName: params.entity.internalName,
      friendlyName: params.entity.friendlyName,
    }),
    token: params.token,
  });
