import { ActionsObservable, Epic } from "redux-observable";
import { push } from "connected-react-router";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as clientAccessesApi from "api/clientAccesses/clientAccessesApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { clientAccessesSlice } from "../slice";

// Types
import { Credentials } from "api/types";
import { FetchResponse } from "modules/api/types";
import ClientAccess from "types/entities/clientAccess";

// Utils
import getEntityRoutePath from "modules/routing/getEntityRoutePath";
import getCredentialsHTML from "modules/entity/getCredentialsHTML";

const createSuccessHandler = ({
  data: { clientAccess, credentials },
}: FetchResponse<{
  clientAccess: ClientAccess;
  credentials: Credentials;
}>) =>
  of(
    clientAccessesSlice.actions.createSuccess({
      entity: clientAccess,
    }),
    clientAccessesSlice.actions.fetchById({ id: clientAccess.id }),
    push(getEntityRoutePath(clientAccess.id, ROUTES_NAME.CLIENT_ACCESSES_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: `Voici les identifiants de connexion à communiquer au client : ${getCredentialsHTML(
        credentials.client_id,
        credentials.client_secret
      )}`,
      title: "Le client access a été créé avec succès.",
    })
  );

const createErrorHandler = (error: Error) =>
  of(
    clientAccessesSlice.actions.createError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la création du client access.",
    })
  );

const createEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof clientAccessesSlice.actions.create>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(clientAccessesSlice.actions.create.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        clientAccessesApi.create({ entity: payload.entity, token })
      ).pipe(
        switchMap(createSuccessHandler),
        catchError(createErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default createEpic;
