import { ActionsObservable, Epic } from "redux-observable";
import { push } from "connected-react-router";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as boxApi from "api/box/boxApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { collectorsSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import Box from "types/entities/box";

// Utils
import getEntityRoutePath from "modules/routing/getEntityRoutePath";
import { boxesV3ConfigurationsSlice } from "store/features/boxesV3Configurations/slice";
import { BoxesV3ConfigurationsCreateApiModel } from "types/entities/boxesV3Configurations";

const createSuccessHandler = ({
  data: entity,
}: FetchResponse<
  Box & BoxesV3ConfigurationsCreateApiModel & { isV3CollectorType: boolean }
>) =>
  of(
    collectorsSlice.actions.createSuccess({
      entity,
    }),
    entity.isV3CollectorType
      ? boxesV3ConfigurationsSlice.actions.create({
          entity: {
            boxId: entity.id,
            bowlsCapacity: entity.bowlsCapacity,
            cupsCapacity: entity.cupsCapacity,
            storageFullAlerts: entity.storageFullAlerts,
          },
        })
      : notificationSlice.actions.clean(),
    push(getEntityRoutePath(entity.id, ROUTES_NAME.COLLECTORS_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "Le collecteur a été créé avec succès.",
    })
  );

const createErrorHandler = (error: Error) =>
  of(
    collectorsSlice.actions.createError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message: "Une erreur s'est produite lors de la création du collecteur.",
    })
  );

const createEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof collectorsSlice.actions.create>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(collectorsSlice.actions.create.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;
      const {
        storageFullAlerts,
        cupsCapacity,
        bowlsCapacity,
        boxId,
        isV3CollectorType,
      } = payload.entity;
      return from(boxApi.create({ entity: payload.entity, token })).pipe(
        switchMap((params) =>
          createSuccessHandler({
            data: {
              ...params.data,
              isV3CollectorType,
              storageFullAlerts,
              cupsCapacity,
              bowlsCapacity,
              boxId,
            },
            headers: params.headers,
          })
        ),
        catchError(createErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default createEpic;
