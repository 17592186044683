// App module
import { SLICE_NAME as APP_SLICE_NAME } from "./features/app/constants";
import appSliceReducer from "./features/app/slice";

// Sites module
import { SLICE_NAME as SITES_SLICE_NAME } from "./features/sites/constants";
import sitesReducer from "./features/sites/slice";

// ClientAccesses module
import { SLICE_NAME as CLIENT_ACCESSES_SLICE_NAME } from "./features/clientAccesses/constants";
import clientAccessesReducer from "./features/clientAccesses/slice";

// Collectors module
import { SLICE_NAME as COLLECTORS_SLICE_NAME } from "./features/collectors/constants";
import collectorsReducer, { collectorsApi } from "./features/collectors/slice";

// CollectorTypes module
import { SLICE_NAME as COLLECTOR_TYPES_SLICE_NAME } from "./features/collectorTypes/constants";
import collectorTypesReducer from "./features/collectorTypes/slice";

// CurrentUser module
import { SLICE_NAME as CURRENT_USER_SLICE_NAME } from "./features/currentUser/constants";
import currentUserSliceReducer from "./features/currentUser/slice";

// Items module
import { SLICE_NAME as ITEMS_SLICE_NAME } from "./features/items/constants";
import itemsSliceReducer from "./features/items/slice";

// ItemTypes module
import { SLICE_NAME as ITEM_TYPES_SLICE_NAME } from "./features/itemTypes/constants";
import itemTypesSliceReducer from "./features/itemTypes/slice";

// ItemTypesCategories module
import { SLICE_NAME as ITEM_TYPES_CATEGORIES_SLICE_NAME } from "./features/itemTypesCategories/constants";
import itemTypesCategoriesSliceReducer from "./features/itemTypesCategories/slice";

// LastTransactionsIn module
import { SLICE_NAME as LAST_TRANSACTIONS_IN_SLICE_NAME } from "./features/lastTransactionsIn/constants";
import lastTransactionsInSliceReducer from "./features/lastTransactionsIn/slice";

// LastTransactionsOut module
import { SLICE_NAME as LAST_TRANSACTIONS_OUT_SLICE_NAME } from "./features/lastTransactionsOut/constants";
import lastTransactionsOutSliceReducer from "./features/lastTransactionsOut/slice";

// Notification module
import { SLICE_NAME as NOTIFICATION_SLICE_NAME } from "./features/notification/constants";
import notificationSliceReducer from "./features/notification/slice";

// PointsOfSales module
import { SLICE_NAME as POINTS_OF_SALES_SLICE_NAME } from "./features/pointsOfSales/constants";
import pointsOfSalesReducer from "./features/pointsOfSales/slice";

// PosMachines module
import { SLICE_NAME as POS_MACHINES_SLICE_NAME } from "./features/posMachines/constants";
import posMachinesReducer from "./features/posMachines/slice";

// Restaurateurs module
import { SLICE_NAME as RESTAURATEURS_SLICE_NAME } from "./features/restaurateurs/constants";
import restaurantOwnersReducer from "./features/restaurateurs/slice";

// Restaurants module
import { SLICE_NAME as RESTAURANTS_SLICE_NAME } from "./features/restaurants/constants";
import restaurantsReducer from "./features/restaurants/slice";

// Services module
import { SLICE_NAME as SERVICES_SLICE_NAME } from "./features/services/constants";
import servicesReducer from "./features/services/slice";

// TransactionsByUser module
import { SLICE_NAME as TRANSACTIONS_BY_USER_SLICE_NAME } from "./features/transactionsByUser/constants";
import transactionsByUserReducer from "./features/transactionsByUser/slice";

// TransactionsIn module
import { SLICE_NAME as TRANSACTIONS_IN_SLICE_NAME } from "./features/transactionsIn/constants";
import transactionsInReducer from "./features/transactionsIn/slice";

// TransactionsOut module
import { SLICE_NAME as TRANSACTIONS_OUT_SLICE_NAME } from "./features/transactionsOut/constants";
import transactionsOutReducer from "./features/transactionsOut/slice";

// Users module
import { SLICE_NAME as TECH_PROVIDERS_SLICE_NAME } from "./features/techProviders/constants";
import techProvidersReducer from "./features/techProviders/slice";

// Users module
import { SLICE_NAME as USERS_SLICE_NAME } from "./features/users/constants";
import usersSliceReducer from "./features/users/slice";

// RecreditUsers module
import { SLICE_NAME as RECREDIT_USER_SLICE_NAME } from "./features/recreditUsers/constants";
import recreditUsersReducer from "./features/recreditUsers/slice";

// RestaurantNetworks module
import { SLICE_NAME as RESTAURANT_NETWORKS_NAME } from "./features/restaurantNetworks/constants";
import restaurantNetworksReducer from "./features/restaurantNetworks/slice";

import { SLICE_NAME as BOX_V3_CONFIGURATIONS_NAME } from "./features/boxesV3Configurations/constants";

import boxesV3ConfigurationsReducer from "./features/boxesV3Configurations/slice";

/** Redux Toolkit Query Reducers */
import { gameConfigApi } from "./features/gamesConfiguration/slice";
import { cautionsAPI } from "./features/cautions/slice";
import { lessorConfigurationApi } from "./features/sites/leasing/lessorConfiguration/slice";
import { externalDistributorConfigurationApi } from "./features/externalDistributorConfiguration/slice";
import { paymentSytemConfigurationApi } from "./features/sites/leasing/paymentSystemConfiguration/slice";
import { resetPasswordAPI } from "./features/resetPassword/slice";
import { partnerApiAccessApi } from "./features/sites/partnerAccessApi/slice";
import { partnersApi } from "./features/partners/slice";
import { boxTypeV3ConfigurationsApi } from "./features/sites/boxTypeV3Configurations/slice";
import { etfOdysseyConfigurationApi } from "./features/sites/etfConfiguration/slice";
import { siteNetworkApi } from "./features/siteNetworks/slice";

//---------------------------------------------------
// RTK slices
//---------------------------------------------------

const sliceReducersRTK = {
  [APP_SLICE_NAME]: appSliceReducer,
  [SITES_SLICE_NAME]: sitesReducer,
  [CLIENT_ACCESSES_SLICE_NAME]: clientAccessesReducer,
  [COLLECTORS_SLICE_NAME]: collectorsReducer,
  [COLLECTOR_TYPES_SLICE_NAME]: collectorTypesReducer,
  [CURRENT_USER_SLICE_NAME]: currentUserSliceReducer,
  [ITEMS_SLICE_NAME]: itemsSliceReducer,
  [ITEM_TYPES_SLICE_NAME]: itemTypesSliceReducer,
  [LAST_TRANSACTIONS_IN_SLICE_NAME]: lastTransactionsInSliceReducer,
  [LAST_TRANSACTIONS_OUT_SLICE_NAME]: lastTransactionsOutSliceReducer,
  [NOTIFICATION_SLICE_NAME]: notificationSliceReducer,
  [POINTS_OF_SALES_SLICE_NAME]: pointsOfSalesReducer,
  [POS_MACHINES_SLICE_NAME]: posMachinesReducer,
  [RESTAURATEURS_SLICE_NAME]: restaurantOwnersReducer,
  [RESTAURANTS_SLICE_NAME]: restaurantsReducer,
  [SERVICES_SLICE_NAME]: servicesReducer,
  [TECH_PROVIDERS_SLICE_NAME]: techProvidersReducer,
  [TRANSACTIONS_BY_USER_SLICE_NAME]: transactionsByUserReducer,
  [TRANSACTIONS_IN_SLICE_NAME]: transactionsInReducer,
  [TRANSACTIONS_OUT_SLICE_NAME]: transactionsOutReducer,
  [USERS_SLICE_NAME]: usersSliceReducer,
  [ITEM_TYPES_CATEGORIES_SLICE_NAME]: itemTypesCategoriesSliceReducer,
  [RECREDIT_USER_SLICE_NAME]: recreditUsersReducer,
  [RESTAURANT_NETWORKS_NAME]: restaurantNetworksReducer,
  [BOX_V3_CONFIGURATIONS_NAME]: boxesV3ConfigurationsReducer,
};

//---------------------------------------------------
// RTKQuery Slices
//---------------------------------------------------

const sliceReducersRTKQuery = {
  [gameConfigApi.reducerPath]: gameConfigApi.reducer,
  [cautionsAPI.reducerPath]: cautionsAPI.reducer,
  [lessorConfigurationApi.reducerPath]: lessorConfigurationApi.reducer,
  [externalDistributorConfigurationApi.reducerPath]:
    externalDistributorConfigurationApi.reducer,
  [paymentSytemConfigurationApi.reducerPath]:
    paymentSytemConfigurationApi.reducer,
  [partnerApiAccessApi.reducerPath]: partnerApiAccessApi.reducer,
  [resetPasswordAPI.reducerPath]: resetPasswordAPI.reducer,
  [collectorsApi.reducerPath]: collectorsApi.reducer,
  [partnersApi.reducerPath]: partnersApi.reducer,
  [siteNetworkApi.reducerPath]: siteNetworkApi.reducer,
  [boxTypeV3ConfigurationsApi.reducerPath]: boxTypeV3ConfigurationsApi.reducer,
  [etfOdysseyConfigurationApi.reducerPath]: etfOdysseyConfigurationApi.reducer,
};

//---------------------------------------------------
// All Slices
//---------------------------------------------------

const sliceReducers = {
  ...sliceReducersRTK,
  ...sliceReducersRTKQuery,
};

export default sliceReducers;
