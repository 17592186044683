import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as techProvidersApi from "api/techProviders/techProvidersApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { techProvidersSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import TechProvider from "types/entities/techProvider";

const fetchAllSuccessHandler = ({
  data: entities,
}: FetchResponse<TechProvider[]>) =>
  of(
    techProvidersSlice.actions.fetchAllSuccess({
      entities,
    })
  );

const fetchAllErrorHandler = (error: Error) =>
  of(
    techProvidersSlice.actions.fetchAllError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la récupération des fournisseurs.",
    })
  );

const fetchAllEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof techProvidersSlice.actions.fetchAll>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(techProvidersSlice.actions.fetchAll.type)),
    switchMap(() => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(techProvidersApi.fetchAll({ token })).pipe(
        switchMap(fetchAllSuccessHandler),
        catchError(fetchAllErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default fetchAllEpic;
