import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as restaurateursApi from "api/restaurateurs/restaurateursApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { restaurantOwnersSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import Restaurateur from "types/entities/restaurateur";

const fetchByIdSuccessHandler = ({
  data: entity,
}: FetchResponse<Restaurateur>) =>
  of(
    restaurantOwnersSlice.actions.fetchByIdSuccess({
      entity,
    })
  );

const fetchByIdErrorHandler = (error: Error) =>
  of(
    restaurantOwnersSlice.actions.fetchByIdError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la récupération du restaurateur.",
    })
  );

const fetchByIdEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof restaurantOwnersSlice.actions.fetchById>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(restaurantOwnersSlice.actions.fetchById.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(restaurateursApi.fetchById({ id: payload.id, token })).pipe(
        switchMap(fetchByIdSuccessHandler),
        catchError(fetchByIdErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default fetchByIdEpic;
