// Constants
import { LOGIN_ENDPOINT, REFRESH_TOKEN_ENDPOINT } from "./constants";

// Types
import User from "types/entities/user";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";

export const login = (params: { email: string; password: string }) =>
  fetch<User>(getApiUrl(LOGIN_ENDPOINT), {
    body: JSON.stringify(params),
    method: "POST",
  });

export const refreshToken = (params: { refreshToken: string }) =>
  fetch<User>(getApiUrl(REFRESH_TOKEN_ENDPOINT), {
    method: "GET",
    refreshToken: params.refreshToken,
  });
