import React, { FC } from "react";

type Props = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const Logo: FC<Props> = (props) => (
  <img
    alt="MyGreenGo"
    src={`${process.env.PUBLIC_URL}/images/logo.svg`}
    {...props}
  />
);

export default Logo;
