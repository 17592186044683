import React, { FC, ReactNode } from "react";
import BsModal, { ModalProps } from "react-bootstrap/Modal";

type Props = {
  body?: ReactNode;
  footer?: ReactNode;
  isCentered?: boolean;
  isOpen: boolean;
  onHide?: () => void;
  size?: ModalProps["size"];
  title?: ReactNode;
};

const Modal: FC<Props> = ({
  body,
  footer,
  isCentered,
  isOpen,
  onHide,
  size,
  title,
}) => (
  <BsModal centered={isCentered} onHide={onHide} show={isOpen} size={size}>
    {title && (
      <BsModal.Header closeButton={Boolean(onHide)}>
        <BsModal.Title>{title}</BsModal.Title>
      </BsModal.Header>
    )}
    {body && <BsModal.Body>{body}</BsModal.Body>}
    {footer && <BsModal.Footer>{footer}</BsModal.Footer>}
  </BsModal>
);

export default Modal;
