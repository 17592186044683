import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import Item from "types/entities/item";

// Utils
import { createBaseReducers, createSearchReducers } from "store/utils/reducers";
import { updateSuccessState } from "store/utils/stateUpdater";

export const itemsAdapter = createEntityAdapter<Item>();

const { fetchAllSuccess, ...baseReducers } = createBaseReducers<Item>(
  itemsAdapter
);

export const itemsSlice = createSlice({
  name: SLICE_NAME,
  initialState: itemsAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    ...baseReducers,
    ...createSearchReducers<Item>(itemsAdapter),
    fetchAllSuccess: (
      state,
      { payload }: PayloadAction<{ count: number; entities: Item[] }>
    ) => {
      state.count = payload.count;

      itemsAdapter.setAll(state, payload.entities);
      updateSuccessState(state);
    },
  },
  extraReducers: {
    [LOCATION_CHANGE]: (state) => {
      state.searchResultsIds = null;
    },
  },
});

export default itemsSlice.reducer;
