import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import User from "types/entities/user";

// Utils
import { createBaseReducers, createSearchReducers } from "store/utils/reducers";

export const usersAdapter = createEntityAdapter<User>();

const {
  create,
  createError,
  createSuccess,
  ...baseReducers
} = createBaseReducers<User>(usersAdapter);

export const usersSlice = createSlice({
  name: SLICE_NAME,
  initialState: usersAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    ...baseReducers,
    ...createSearchReducers<User>(usersAdapter),
  },
  extraReducers: {
    [LOCATION_CHANGE]: (state) => {
      state.searchResultsIds = null;
    },
  },
});

export default usersSlice.reducer;
