import { EntityId } from "@reduxjs/toolkit";

// Constants
import { API_ENDPOINT } from "./constants";

// Types
import { Credentials } from "api/types";
import TechProvider from "types/entities/techProvider";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";

const baseUrl = getApiUrl(API_ENDPOINT);

export const create = (params: { entity: TechProvider; token: string }) =>
  fetch<{ credentials: Credentials; tech_provider: TechProvider }>(
    `${baseUrl}/new`,
    {
      body: JSON.stringify(params.entity),
      method: "POST",
      token: params.token,
    }
  );

export const fetchAll = (params: { token: string }) =>
  fetch<TechProvider[]>(baseUrl, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { id: EntityId; token: string }) =>
  fetch<TechProvider>(`${baseUrl}/${params.id}`, {
    method: "GET",
    token: params.token,
  });

export const update = (params: { entity: TechProvider; token: string }) =>
  fetch<TechProvider>(`${baseUrl}/${params.entity.id}`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });
