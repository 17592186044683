import { combineEpics } from "redux-observable";

// Epics
import getProfileEpic from "./epics/getProfileEpic";
import loginEpic from "./epics/loginEpic";
import logoutEpic from "./epics/logoutEpic";
import refreshTokenEpic from "./epics/refreshTokenEpic";

const epic = combineEpics(
  getProfileEpic,
  loginEpic,
  logoutEpic,
  refreshTokenEpic
);

export default epic;
