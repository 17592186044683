import { RootState } from "store";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getApiUrl from "modules/api/getApiUrl";
import { currentUserTokenSelector } from "../currentUser/selectors";
import {
  DistributionSystemConfiguration,
  DistributionSystemName,
  DistributionSystemSearchParams,
  UpdateDistributionSystemConfiguration,
  UpdateDistributionResponse,
} from "types/entities/distributions/distribution";

const CACHE_KEY_EXTERNAL_DISTRIBUTOR_CONFIGURATION =
  "ExternalDistributorConfiguration";

export const externalDistributorConfigurationApi = createApi({
  reducerPath: "externalDistributorConfigurationApi",

  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(""),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),

  tagTypes: [CACHE_KEY_EXTERNAL_DISTRIBUTOR_CONFIGURATION],

  endpoints: (builder) => ({
    getExternalDistributorConfiguration: builder.query<
      DistributionSystemConfiguration<DistributionSystemName>,
      DistributionSystemSearchParams
    >({
      query: ({ distributorRef, distributorType }) =>
        `/admin/distribution/external-distributors/configuration?distributorRef=${distributorRef}&distributorType=${distributorType}`,

      providesTags: (_result, _error, { distributorRef }) => [
        {
          type: CACHE_KEY_EXTERNAL_DISTRIBUTOR_CONFIGURATION,
          id: distributorRef,
        },
      ],
    }),

    upsertExternalDistributorConfiguration: builder.mutation<
      UpdateDistributionResponse,
      UpdateDistributionSystemConfiguration
    >({
      query: ({ distributorRef, distributorType, configuration }) => ({
        method: "POST",
        url: `/admin/distribution/external-distributors/configuration?distributorRef=${distributorRef}&distributorType=${distributorType}`,
        body: configuration,
      }),
      invalidatesTags: [CACHE_KEY_EXTERNAL_DISTRIBUTOR_CONFIGURATION],
    }),
  }),
});

export const {
  useGetExternalDistributorConfigurationQuery,
  useUpsertExternalDistributorConfigurationMutation,
} = externalDistributorConfigurationApi;
