import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import TransactionIn, {
  TransactionInRequest,
} from "types/entities/transactionIn";

// Utils
import {
  createReducer,
  createSuccessReducer,
  errorReducer,
} from "store/utils/reducers";

export const transactionsInAdapter = createEntityAdapter<TransactionIn>();

export const transactionsInSlice = createSlice({
  name: SLICE_NAME,
  initialState: transactionsInAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    create: createReducer<TransactionIn, { entity: TransactionInRequest }>(),
    createError: errorReducer<TransactionIn>(),
    createSuccess: createSuccessReducer<TransactionIn>(transactionsInAdapter),
  },
});

export default transactionsInSlice.reducer;
