/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Hooks
import useRefreshToken from "./useRefreshToken";
import useToken from "./useToken";

// Slices
import { currentUserSlice } from "store/features/currentUser/slice";

const useAuth = () => {
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPending, setIsPending] = useState(true);

  const { isTokenValid, token } = useToken();
  const {
    isRefreshTokenSuccess,
    isRefreshTokenValid,
    refreshToken,
  } = useRefreshToken();

  useEffect(() => {
    if (!token || !refreshToken) {
      setIsPending(false);
    } else if (isTokenValid) {
      setIsAuthenticated(true);
    } else if (isRefreshTokenValid) {
      dispatch(currentUserSlice.actions.refreshToken());
    } else {
      setIsPending(false);
    }
  }, []);

  useEffect(() => {
    if (isRefreshTokenSuccess) {
      setIsAuthenticated(true);
    }
  }, [isRefreshTokenSuccess]);

  return { isAuthenticated, isPending };
};

export default useAuth;
