import { EntityId } from "@reduxjs/toolkit";
import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as usersApi from "api/users/usersApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { transactionsByUserSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import transactionsByUser from "types/entities/transactionsByUser";

const fetchAllSuccessHandler = (userId: EntityId) => ({
  data: transactionsByUser,
}: FetchResponse<transactionsByUser>) =>
  of(
    transactionsByUserSlice.actions.fetchAllSuccess({
      transactions: transactionsByUser,
      userId,
    })
  );

const fetchAllErrorHandler = (error: Error) =>
  of(
    transactionsByUserSlice.actions.fetchAllError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la récupération des transactions de l'utilisateur.",
    })
  );

const fetchAllEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof transactionsByUserSlice.actions.fetchAll>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(transactionsByUserSlice.actions.fetchAll.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        usersApi.fetchTransactionsByUserId({ id: payload.userId, token })
      ).pipe(
        switchMap(fetchAllSuccessHandler(payload.userId)),
        catchError(fetchAllErrorHandler)
      );
    })
  );

export default fetchAllEpic;
