// Types

import { InitialState } from "./types";

export const SLICE_NAME = "restaurantNetworks";

export const INITIAL_STATE: InitialState = {
  name: "",
  daysBeforeExpiration: 0,
  expirationPenaltyFee: 0,
  daysBeforeExpiration2: 0,
  refundAmount: 0,
  error: null,
  status: "INITIAL",
  statusAction: null,
};
