import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import Service from "types/entities/service";

// Utils
import {
  errorReducer,
  fetchAllReducer,
  fetchAllSuccessReducer,
  fetchByIdReducer,
  fetchByIdSuccessReducer,
} from "store/utils/reducers";

export const servicesAdapter = createEntityAdapter<Service>();

export const servicesSlice = createSlice({
  name: SLICE_NAME,
  initialState: servicesAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    fetchAll: fetchAllReducer<Service>(),
    fetchAllError: errorReducer<Service>(),
    fetchAllSuccess: fetchAllSuccessReducer<Service>(servicesAdapter),
    fetchById: fetchByIdReducer<Service>(),
    fetchByIdError: errorReducer<Service>(),
    fetchByIdSuccess: fetchByIdSuccessReducer<Service>(servicesAdapter),
  },
});

export default servicesSlice.reducer;
