import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import { NotificationType } from "./types";

export const notificationSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    addErrorNotification: (
      state,
      {
        payload,
      }: PayloadAction<{
        message: string;
        namespace?: string;
        title?: string;
        shouldCleanOnRouteChange?: boolean;
      }>
    ) => {
      state.message = payload.message;
      state.namespace = payload.namespace;
      state.title = payload.title;
      state.shouldCleanOnRouteChange = payload.shouldCleanOnRouteChange ?? true;
      state.type = NotificationType.ERROR;
    },
    addSuccessNotification: (
      state,
      {
        payload,
      }: PayloadAction<{
        message: string;
        namespace?: string;
        title?: string;
        shouldCleanOnRouteChange?: boolean;
      }>
    ) => {
      state.message = payload.message;
      state.namespace = payload.namespace;
      state.title = payload.title;
      state.shouldCleanOnRouteChange = payload.shouldCleanOnRouteChange ?? true;
      state.type = NotificationType.SUCCESS;
    },
    clean: () => INITIAL_STATE,
  },
});

export default notificationSlice.reducer;
