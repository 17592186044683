import { FullTagDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getApiUrl from "modules/api/getApiUrl";
import { RootState } from "store";
import { currentUserTokenSelector } from "../currentUser/selectors";
import SiteNetwork from "types/entities/siteNetwork";
import { notificationSlice } from "../notification/slice";
import { generateErrorMessage } from "util/generateErrorMessage";

const CACHE_KEY_SITE_NETWORK = "SiteNetwork" as const;

export type UpdateSiteNetworkBody = Omit<SiteNetwork, "sites">;
export type CreateSiteNetworkBody = Omit<SiteNetwork, "id" | "sites">;
export type FindNetworksParams = {
  filter?: string;
  offset?: number;
  limit?: number;
};

export const siteNetworkApi = createApi({
  reducerPath: "siteNetworkApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl("networks"),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),
  tagTypes: [CACHE_KEY_SITE_NETWORK],
  endpoints: (builder) => ({
    getSiteNetwork: builder.query<SiteNetwork, number>({
      query: (siteNetworkId) => `${siteNetworkId}`,
      providesTags: (_result, _error, siteNetworkId) => [
        { type: CACHE_KEY_SITE_NETWORK, id: siteNetworkId },
      ],
    }),
    getSiteNetworks: builder.query<
      SiteNetwork[],
      FindNetworksParams | undefined
    >({
      query: (params) => ({
        url: ``,
        params: params ?? {},
      }),
      providesTags: (_result, _error) => [{ type: CACHE_KEY_SITE_NETWORK }],
      transformResponse: (response: any) => response.entities,
      onQueryStarted: async (entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          notificationSlice.actions.addErrorNotification({
            message: `Une erreur s'est produite lors de la récupération des réseaux.<p>${generateErrorMessage(
              error
            )}</p>`,
          });
        }
      },
    }),
    createSiteNetwork: builder.mutation<{ id: number }, CreateSiteNetworkBody>({
      query: (body) => ({
        method: "POST",
        url: ``,
        body: body,
      }),
      onQueryStarted: async (entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            notificationSlice.actions.addSuccessNotification({
              message: `Le réseaux "${entity.name}" a été créé avec succès.`,
              shouldCleanOnRouteChange: false,
            })
          );
        } catch (error) {
          notificationSlice.actions.addErrorNotification({
            message: `Une erreur s'est produite lors de la création du réseau.<p>${generateErrorMessage(
              error
            )}</p>`,
          });
        }
      },
    }),
    updateSiteNetwork: builder.mutation<void, UpdateSiteNetworkBody>({
      query: ({ id, ...entity }) => ({
        url: `${id}`,
        method: "PUT",
        body: entity,
      }),
      onQueryStarted: async (entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            notificationSlice.actions.addSuccessNotification({
              message: `Le réseaux "${entity.name}" a été updaté avec succès.`,
            })
          );
        } catch (error) {
          dispatch(
            notificationSlice.actions.addErrorNotification({
              message: `Une erreur s'est produite lors de la modification du réseau.<p>${generateErrorMessage(
                error
              )}</p>`,
            })
          );
        }
      },
      invalidatesTags: (
        _result,
        _error,
        arg
      ): FullTagDescription<typeof CACHE_KEY_SITE_NETWORK>[] => {
        return [{ type: CACHE_KEY_SITE_NETWORK, id: arg.id }];
      },
    }),
    deleteSiteNetwork: builder.mutation<void, number>({
      query: (siteNetworkId) => ({
        url: `${siteNetworkId}`,
        method: "DELETE",
      }),
      onQueryStarted: async (_entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            notificationSlice.actions.addSuccessNotification({
              message: `Le réseaux a été supprimé avec avec succès.`,
              shouldCleanOnRouteChange: false,
            })
          );
        } catch (error) {
          dispatch(
            notificationSlice.actions.addErrorNotification({
              message: `Une erreur s'est produite lors de la suppression du réseau.<p>${generateErrorMessage(
                error
              )}</p>`,
            })
          );
        }
      },
      invalidatesTags: (_result, _error, _siteNetworkId) => [
        { type: CACHE_KEY_SITE_NETWORK },
      ],
    }),
  }),
});
