import { RootState } from "store";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getApiUrl from "modules/api/getApiUrl";
import { currentUserTokenSelector } from "../currentUser/selectors";
import Partners from "types/entities/partners";
import { notificationSlice } from "../notification/slice";
import { cleanPartners } from "./partners.utils";

const CACHE_KEY_PARTNERS = "Partners";

export const partnersApi = createApi({
  reducerPath: "partnersApi",

  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(""),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),

  tagTypes: [CACHE_KEY_PARTNERS],

  endpoints: (builder) => ({
    getPartners: builder.query<Partners[], string>({
      query: (query) => `/admin/partners?query=${query}`,
    }),

    getPartner: builder.query<Partners, number>({
      query: (id) => `/admin/partners/${id}`,
    }),

    createPartner: builder.mutation<number, Omit<Partners, "id">>({
      query: (body) => ({
        method: "POST",
        url: `/admin/partners`,
        body: cleanPartners(body),
      }),
      onQueryStarted: async (entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            notificationSlice.actions.addSuccessNotification({
              message: `Le partenaire "${entity.name}" a été créé avec succès.`,
            })
          );
        } catch (error) {
          dispatch(
            notificationSlice.actions.addErrorNotification({
              message:
                "Une erreur s'est produite lors de la création du partenaire.",
            })
          );
        }
      },
    }),

    updatePartner: builder.mutation<number, Partners>({
      query: ({ id, ...body }) => ({
        method: "PATCH",
        url: `/admin/partners/${id}`,
        body: cleanPartners(body),
      }),
      onQueryStarted: async (entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            notificationSlice.actions.addSuccessNotification({
              message: "Le partenaire a été mis à jour avec succès.",
            })
          );
        } catch (error) {
          dispatch(
            notificationSlice.actions.addErrorNotification({
              message:
                "Une erreur s'est produite lors de la mise à jour du partenaire.",
            })
          );
        }
      },
    }),
  }),
});

export const {
  useGetPartnersQuery,
  useGetPartnerQuery,
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
} = partnersApi;
