// APIs
import * as pointsOfSalesApi from "api/pointsOfSales/pointsOfSalesApi";
// Types
import { FetchResponse } from "modules/api/types";
import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";
// Slices
import { notificationSlice } from "store/features/notification/slice";
import PointOfSale from "types/entities/pointOfSale";
import { ActionType, isOfType } from "typesafe-actions";
import { pointsOfSalesSlice } from "../slice";

const searchSuccessHandler = ({
  data: { count, results: entities },
}: FetchResponse<{ count: number; results: PointOfSale[] }>) =>
  of(pointsOfSalesSlice.actions.searchSuccess({ count, entities }));

const searchErrorHandler = (error: Error) =>
  of(
    pointsOfSalesSlice.actions.searchError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la recherche de point(s) de collecte.",
    })
  );

const searchEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof pointsOfSalesSlice.actions.search>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(pointsOfSalesSlice.actions.search.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        pointsOfSalesApi.search({
          filter: payload.filter,
          offset: payload.offset,
          token,
        })
      ).pipe(
        switchMap(searchSuccessHandler),
        catchError(searchErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default searchEpic;
