import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import TransactionOut from "types/entities/transactionOut";

// Utils
import { createBaseReducers } from "store/utils/reducers";

export const lastTransactionsOutAdapter = createEntityAdapter<TransactionOut>();

const {
  fetchAll,
  fetchAllError,
  fetchAllSuccess,
} = createBaseReducers<TransactionOut>(lastTransactionsOutAdapter);

export const lastTransactionsOutSlice = createSlice({
  name: SLICE_NAME,
  initialState: lastTransactionsOutAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    fetchAll,
    fetchAllError,
    fetchAllSuccess,
  },
});

export default lastTransactionsOutSlice.reducer;
