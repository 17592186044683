import { ActionsObservable, Epic } from "redux-observable";
import { push } from "connected-react-router";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as pointsOfSalesApi from "api/pointsOfSales/pointsOfSalesApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { pointsOfSalesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import PointOfSale from "types/entities/pointOfSale";

// Utils
import getEntityRoutePath from "modules/routing/getEntityRoutePath";

const updateSuccessHandler = ({ data: entity }: FetchResponse<PointOfSale>) =>
  of(
    pointsOfSalesSlice.actions.updateSuccess({
      entity,
    }),
    push(getEntityRoutePath(entity.id, ROUTES_NAME.POINTS_OF_SALES_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "Le point de collecte a été mis à jour avec succès.",
    })
  );

const updateErrorHandler = (error: Error) =>
  of(
    pointsOfSalesSlice.actions.updateError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la mise à jour du point de collecte.",
    })
  );

const updateEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof pointsOfSalesSlice.actions.update>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(pointsOfSalesSlice.actions.update.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        pointsOfSalesApi.update({ entity: payload.entity, token })
      ).pipe(
        switchMap(updateSuccessHandler),
        catchError(updateErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default updateEpic;
