// Constants
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from "modules/auth/constants";

// Types
import { FetchParams, FetchResponse } from "./types";

// Utils
import getErrorMessage from "./getErrorMessage";

const fetch = async <ResponseData>(
  url: string,
  { token, refreshToken, requestId, ...params }: FetchParams
): Promise<FetchResponse<ResponseData>> => {
  const headers = new Headers({
    "content-type": "application/json",
  });

  if (token) {
    headers.append(TOKEN_KEY, token ?? "");
  }

  if (requestId) {
    headers.append("x-request-id", requestId);
  }

  if (refreshToken) {
    headers.append(REFRESH_TOKEN_KEY, refreshToken ?? "");
  }

  const response = await window.fetch(url, {
    ...params,
    headers,
  });
  let errorMessage;
  let responseData;

  try {
    responseData = await response.json();
  } catch {
    errorMessage = String(response.status);
  }

  if (!response.ok) {
    const message = getErrorMessage(responseData, requestId) || errorMessage;

    throw new Error(message);
  }

  return {
    data: responseData,
    headers: response.headers,
  };
};

export default fetch;
