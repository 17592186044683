import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import Restaurateur, {
  RestaurantOwnerRequest,
} from "types/entities/restaurateur";

// Utils
import {
  createBaseReducers,
  createReducer,
  createSearchReducers,
  updateReducer,
} from "store/utils/reducers";
import { updateSuccessState } from "store/utils/stateUpdater";

export const restaurantOwnersAdapter = createEntityAdapter<Restaurateur>();

const { create, fetchAllSuccess, update, ...baseReducers } =
  createBaseReducers<Restaurateur>(restaurantOwnersAdapter);

export const restaurantOwnersSlice = createSlice({
  name: SLICE_NAME,
  initialState: restaurantOwnersAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    ...baseReducers,
    ...createSearchReducers<Restaurateur>(restaurantOwnersAdapter),
    create: createReducer<Restaurateur, { entity: RestaurantOwnerRequest }>(),
    fetchAllSuccess: (
      state,
      { payload }: PayloadAction<{ count: number; entities: Restaurateur[] }>
    ) => {
      state.count = payload.count;

      restaurantOwnersAdapter.setAll(state, payload.entities);
      updateSuccessState(state);
    },
    update: updateReducer<Restaurateur, { entity: RestaurantOwnerRequest }>(),
  },
  extraReducers: {
    [LOCATION_CHANGE]: (state) => {
      state.searchResultsIds = null;
    },
  },
});

export default restaurantOwnersSlice.reducer;
