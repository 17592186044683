// Types
import { InitialState } from "./types";

export const SLICE_NAME = "currentUser";

export const INITIAL_STATE: InitialState = {
  getProfileError: null,
  getProfileStatus: "INITIAL",
  id: null,
  loginError: null,
  loginStatus: "INITIAL",
  name: null,
  refreshToken: null,
  refreshTokenError: null,
  refreshTokenStatus: "INITIAL",
  token: null,
};
