import { ActionsObservable, Epic } from "redux-observable";
import { push } from "connected-react-router";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as pointsOfSalesApi from "api/pointsOfSales/pointsOfSalesApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { pointsOfSalesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import PointOfSale from "types/entities/pointOfSale";

// Utils
import getEntityRoutePath from "modules/routing/getEntityRoutePath";

const createSuccessHandler = ({ data: entity }: FetchResponse<PointOfSale>) =>
  of(
    pointsOfSalesSlice.actions.createSuccess({
      entity,
    }),
    push(getEntityRoutePath(entity.id, ROUTES_NAME.POINTS_OF_SALES_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "Le point de collecte a été créé avec succès.",
    })
  );

const createErrorHandler = (error: Error) =>
  of(
    pointsOfSalesSlice.actions.createError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la création du point de collecte.",
    })
  );

const createEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof pointsOfSalesSlice.actions.create>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(pointsOfSalesSlice.actions.create.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        pointsOfSalesApi.create({ entity: payload.entity, token })
      ).pipe(
        switchMap(createSuccessHandler),
        catchError(createErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default createEpic;
