// Constants
import { API_ENDPOINT } from "./constants";

// Types
import TransactionIn, {
  TransactionInRequest,
} from "types/entities/transactionIn";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";

const baseUrl = getApiUrl(API_ENDPOINT);

export const create = (params: {
  entity: TransactionInRequest;
  token: string;
}) =>
  fetch<{ transaction: TransactionIn }>(baseUrl, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });

export const fetchAll = (params: { token: string }) =>
  fetch<TransactionIn[]>(`${baseUrl}/last`, {
    method: "GET",
    token: params.token,
  });
