// APIs
import * as sitesApi from "api/sites/sitesApi";
// Types
import { FetchResponse } from "modules/api/types";
import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";
// Slices
import { notificationSlice } from "store/features/notification/slice";
import Site from "types/entities/site";
import { ActionType, isOfType } from "typesafe-actions";
import { sitesSlice } from "../slice";

const searchSuccessHandler = ({
  data: { count, results: entities },
}: FetchResponse<{
  count: number;
  results: Site[];
}>) =>
  of(
    sitesSlice.actions.searchSuccess({
      count,
      entities,
    })
  );

const searchErrorHandler = (error: Error) =>
  of(
    sitesSlice.actions.searchError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message: "Une erreur s'est produite lors de la recherche de sites.",
    })
  );

const searchEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof sitesSlice.actions.search>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(sitesSlice.actions.search.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        sitesApi.search({
          filter: payload.filter,
          offset: payload.offset,
          token,
        })
      ).pipe(
        switchMap(searchSuccessHandler),
        catchError(searchErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default searchEpic;
