import { useSelector } from "react-redux";

// Selectors
import {
  currentUserTokenSelector,
  isRefreshTokenSuccessSelector,
} from "store/features/currentUser/selectors";

// Utils
import getTokenRemainingTime from "./getTokenRemainingTime";

const useRefreshToken = () => {
  const token = useSelector(currentUserTokenSelector);
  const isRefreshTokenSuccess = useSelector(isRefreshTokenSuccessSelector);
  const tokenRemainingTime = token && getTokenRemainingTime(token);

  return {
    isRefreshTokenSuccess,
    isRefreshTokenValid: tokenRemainingTime ? tokenRemainingTime > 0 : false,
    refreshToken: token,
  };
};

export default useRefreshToken;
