const { REACT_APP_API_BASE_URL, REACT_APP_MOCK_API_BASE_URL } = process.env;

type Params = {
  useMockApi?: boolean;
};

const getApiUrl = (apiEndpoint: string, params: Params = {}) => {
  const baseUrl = params.useMockApi
    ? REACT_APP_MOCK_API_BASE_URL
    : REACT_APP_API_BASE_URL;

  return `${baseUrl}/${apiEndpoint}`;
};

export default getApiUrl;
