import React, { FC } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";

// Components
import Modal from "components/Modal/Modal";

// Slices
import { currentUserSlice } from "store/features/currentUser/slice";

type Props = {
  isOpen: boolean;
  onHide: () => void;
};

const LogoutModal: FC<Props> = ({ isOpen, onHide }) => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(currentUserSlice.actions.logout());
    onHide();
  };

  return (
    <Modal
      body="Voulez-vous vous déconnecter ?"
      footer={
        <>
          <Button onClick={onHide} variant="dark">
            Annuler
          </Button>
          <Button onClick={logout} variant="primary">
            OK
          </Button>
        </>
      }
      isCentered
      isOpen={isOpen}
      onHide={onHide}
      title="Déconnexion"
    />
  );
};

export default LogoutModal;
