import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as clientAccessesApi from "api/clientAccesses/clientAccessesApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { clientAccessesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import ClientAccess from "types/entities/clientAccess";

const fetchAllSuccessHandler = ({
  data: entities,
}: FetchResponse<ClientAccess[]>) =>
  of(
    clientAccessesSlice.actions.fetchAllSuccess({
      entities,
    })
  );

const fetchAllErrorHandler = (error: Error) =>
  of(
    clientAccessesSlice.actions.fetchAllError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la récupération des client access.",
    })
  );

const fetchAllEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof clientAccessesSlice.actions.fetchAll>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(clientAccessesSlice.actions.fetchAll.type)),
    switchMap(() => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(clientAccessesApi.fetchAll({ token })).pipe(
        switchMap(fetchAllSuccessHandler),
        catchError(fetchAllErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default fetchAllEpic;
