import { hasOwnProperty } from "util/asserts";

export type Credentials = {
  client_id: string;
  client_secret: string;
};

export interface ServerError {
  statusCode: number;
  error: string;
  message: string;
}

export function isServerError(error: any): error is ServerError {
  return (
    hasOwnProperty(error, "statusCode") &&
    hasOwnProperty(error, "error") &&
    hasOwnProperty(error, "message")
  );
}
