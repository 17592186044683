import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as usersApi from "api/users/usersApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { usersSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import User from "types/entities/user";

const searchSuccessHandler = ({ data: entities }: FetchResponse<User[]>) =>
  of(
    usersSlice.actions.searchSuccess({
      entities,
    })
  );

const searchErrorHandler = (error: Error) =>
  of(
    usersSlice.actions.searchError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la recherche d'utilisateur(s).",
    })
  );

const searchEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof usersSlice.actions.search>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(usersSlice.actions.search.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(usersApi.search({ filter: payload.filter, token })).pipe(
        switchMap(searchSuccessHandler),
        catchError(searchErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default searchEpic;
