import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as usersApi from "api/users/usersApi";

// Selectors
import { currentUserTokenSelector } from "../selectors";

// Slices
import { currentUserSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import User from "types/entities/user";

const getProfileSuccessHandler = ({ data: user }: FetchResponse<User>) =>
  of(currentUserSlice.actions.getProfileSuccess({ user }));

const getProfileErrorHandler = (error: Error) =>
  of(
    currentUserSlice.actions.getProfileError({ error: error.message }),
    currentUserSlice.actions.logout()
  );

const getProfileEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof currentUserSlice.actions.getProfile>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(currentUserSlice.actions.getProfile.type)),
    switchMap(() => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(usersApi.getCurrentUser({ token })).pipe(
        switchMap(getProfileSuccessHandler),
        catchError(getProfileErrorHandler)
      );
    })
  );

export default getProfileEpic;
