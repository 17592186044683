import { EntityId } from "@reduxjs/toolkit";

// Constants
import { API_ENDPOINT, FETCH_ALL_ENDPOINT } from "./constants";

// Types
import { Credentials } from "api/types";
import ClientAccess from "types/entities/clientAccess";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";

const baseUrl = getApiUrl(API_ENDPOINT);

export const create = (params: { entity: ClientAccess; token: string }) =>
  fetch<{ clientAccess: ClientAccess; credentials: Credentials }>(
    `${baseUrl}/new`,
    {
      body: JSON.stringify(params.entity),
      method: "POST",
      token: params.token,
    }
  );

export const fetchAll = (params: { token: string }) =>
  fetch<ClientAccess[]>(`${getApiUrl(FETCH_ALL_ENDPOINT)}`, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { id: EntityId; token: string }) =>
  fetch<ClientAccess>(`${baseUrl}/${params.id}`, {
    method: "GET",
    token: params.token,
  });

export const update = (params: { entity: ClientAccess; token: string }) =>
  fetch<ClientAccess>(`${baseUrl}/${params.entity.id}`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });
