import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import TechProvider from "types/entities/techProvider";

// Utils
import { createBaseReducers } from "store/utils/reducers";

export const techProvidersAdapter = createEntityAdapter<TechProvider>();

export const techProvidersSlice = createSlice({
  name: SLICE_NAME,
  initialState: techProvidersAdapter.getInitialState(INITIAL_STATE),
  reducers: createBaseReducers<TechProvider>(techProvidersAdapter),
});

export default techProvidersSlice.reducer;
