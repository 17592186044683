import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
// Utils
import {
  createBaseReducers,
  createReducer,
  updateReducer,
} from "store/utils/reducers";
import { updateSuccessState } from "store/utils/stateUpdater";
// Types
import {
  BoxesV3Configurations,
  BoxesV3ConfigurationsCreateApiModel,
  BoxesV3ConfigurationsUpdateApiModel,
} from "types/entities/boxesV3Configurations";
// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

export const boxesV3ConfigurationsAdapter =
  createEntityAdapter<BoxesV3Configurations>({
    selectId: (boxesV3Configurations: BoxesV3Configurations) =>
      boxesV3Configurations.boxId,
  });

const { create, update, ...baseReducers } =
  createBaseReducers<BoxesV3Configurations>(boxesV3ConfigurationsAdapter);

export const boxesV3ConfigurationsSlice = createSlice({
  name: SLICE_NAME,
  initialState: boxesV3ConfigurationsAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    ...baseReducers,
    create: createReducer<
      BoxesV3Configurations,
      { entity: BoxesV3ConfigurationsCreateApiModel }
    >(),
    update: updateReducer<
      BoxesV3Configurations,
      { entity: BoxesV3ConfigurationsUpdateApiModel }
    >(),
    fetchAllSuccess: (
      state,
      { payload }: PayloadAction<BoxesV3Configurations[]>
    ) => {
      boxesV3ConfigurationsAdapter.setAll(state, payload);
      updateSuccessState(state);
    },
  },
  extraReducers: {},
});

export default boxesV3ConfigurationsSlice.reducer;
