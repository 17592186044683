// Types
import { InitialState } from "./types";

export const SLICE_NAME = "techProviders";

export const INITIAL_STATE: InitialState = {
  error: null,
  status: "INITIAL",
  statusAction: null,
};
