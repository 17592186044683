import { EntityId } from "@reduxjs/toolkit";
// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";
// Types
import Box, { BoxRequest } from "types/entities/box";
// Constants
import { API_ENDPOINT } from "./constants";

const baseUrl = getApiUrl(API_ENDPOINT);

export const create = (params: { entity: BoxRequest; token: string }) =>
  fetch<Box>(`${baseUrl}/new`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });

export const emptyById = (params: { id: EntityId; token: string }) =>
  fetch<{ status: string }>(`${baseUrl}/${params.id}/empty`, {
    method: "POST",
    token: params.token,
  });

export const fetchAll = (params: { token: string }) =>
  fetch<{
    count: number;
    results: Box[];
  }>(baseUrl, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { id: EntityId; token: string }) =>
  fetch<Box>(`${baseUrl}/${params.id}`, {
    method: "GET",
    token: params.token,
  });

export const update = (params: { entity: BoxRequest; token: string }) => {
  return fetch<Box>(`${baseUrl}/${params.entity.id}`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });
};
export const search = (params: {
  filter?: string;
  offset?: number;
  token: string;
}) => {
  const searchParams = new URLSearchParams();

  if (params.filter) {
    searchParams.append("filter", params.filter);
  }

  if (params.offset) {
    searchParams.append("offset", String(params.offset));
  }

  return fetch<{
    count: number;
    results: Box[];
  }>(`${baseUrl}?${searchParams.toString()}`, {
    method: "GET",
    token: params.token,
  });
};
