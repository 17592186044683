import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import { recreditUsers as recreditUsersApi } from "api/recreditUsers/recreditUsers";

// Constants

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { recreditUsersSlice } from "../slice";

const createSuccessHandler = () =>
  of(
    recreditUsersSlice.actions.recreditUsersSuccess(),
    notificationSlice.actions.addSuccessNotification({
      message: "Le recrédit de l'utilisateur a été effectué avec succès.",
    })
  );

const createErrorHandler = (error: Error) =>
  of(
    recreditUsersSlice.actions.recreditUsersError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message: `Une erreur s'est produite lors de la création du recrédit : ${error.message}`,
    })
  );

const createEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof recreditUsersSlice.actions.recreditUsers>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(recreditUsersSlice.actions.recreditUsers.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(recreditUsersApi({ entity: payload, token })).pipe(
        switchMap(createSuccessHandler),
        catchError(createErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default createEpic;
