// APIs
import * as boxesV3ConfigurationsAPI from "api/boxesV3Configurations/boxesV3Configurations.api";
// Types
import { FetchResponse } from "modules/api/types";
import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";
// Slices
import { notificationSlice } from "store/features/notification/slice";
import { BoxesV3Configurations } from "types/entities/boxesV3Configurations";
import { ActionType, isOfType } from "typesafe-actions";
import { boxesV3ConfigurationsSlice } from "../slice";

const fetchAllSuccessHandler = ({
  data,
}: FetchResponse<BoxesV3Configurations[]>) =>
  of(boxesV3ConfigurationsSlice.actions.fetchAllSuccess(data));

const fetchAllErrorHandler = (error: Error) =>
  of(
    boxesV3ConfigurationsSlice.actions.fetchAllError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la récupération des collecteurs.",
    })
  );

const fetchAllEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof boxesV3ConfigurationsSlice.actions.fetchAll>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(boxesV3ConfigurationsSlice.actions.fetchAll.type)),
    switchMap(() => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(boxesV3ConfigurationsAPI.fetchAll({ token })).pipe(
        switchMap(fetchAllSuccessHandler),
        catchError(fetchAllErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default fetchAllEpic;
