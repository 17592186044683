import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import { RecreditUsersRequest } from "types/entities/recreditUsers";

// Utils

export const recreditUsersAdapter = createEntityAdapter<RecreditUsersRequest>();

export const recreditUsersSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    recreditUsers: (state, _action: PayloadAction<RecreditUsersRequest>) => {
      state.status = "PENDING";
    },
    recreditUsersSuccess: (state) => {
      state.status = "SUCCESS";
    },
    recreditUsersError: (
      state,
      { payload }: PayloadAction<{ error: string }>
    ) => {
      state.status = "ERROR";
      state.error = payload.error;
    },
  },
});

export default recreditUsersSlice.reducer;
