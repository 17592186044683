import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";
// Utils
import {
  createBaseReducers,
  createReducer,
  createSearchReducers,
  errorReducer,
  updateReducer,
} from "store/utils/reducers";
import {
  updatePendingState,
  updateSuccessState,
} from "store/utils/stateUpdater";
// Types
import Box, { BoxRequest } from "types/entities/box";
import {
  BoxesV3ConfigurationsCreateApiModel,
  BoxesV3ConfigurationsUpdateApiModel,
} from "types/entities/boxesV3Configurations";
// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getApiUrl from "modules/api/getApiUrl";
import { currentUserTokenSelector } from "../currentUser/selectors";
import { RootState } from "store";

export const collectorsAdapter = createEntityAdapter<Box>();

const { create, update, ...baseReducers } =
  createBaseReducers<Box>(collectorsAdapter);

export const collectorsSlice = createSlice({
  name: SLICE_NAME,
  initialState: collectorsAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    ...baseReducers,
    ...createSearchReducers<Box>(collectorsAdapter),
    create: createReducer<
      Box,
      {
        entity: BoxRequest &
          BoxesV3ConfigurationsCreateApiModel & {
            isV3CollectorType: boolean;
          };
      }
    >(),
    empty: (state, _action: PayloadAction<{ id: EntityId }>) => {
      updatePendingState(state, "EMPTY");
    },
    emptyError: errorReducer<Box>(),
    emptySuccess: (state) => {
      updateSuccessState(state, "EMPTY");
    },
    update: updateReducer<
      Box,
      {
        entity: BoxRequest &
          BoxesV3ConfigurationsUpdateApiModel & {
            isV3ConfigurationsExist: boolean;
            isV3CollectorType: boolean;
          };
      }
    >(),
    fetchAllSuccess: (
      state,
      { payload }: PayloadAction<{ count: number; entities: Box[] }>
    ) => {
      state.count = payload.count;

      collectorsAdapter.setAll(state, payload.entities);
      updateSuccessState(state);
    },
    updateCollectorMode(
      state,
      payload: PayloadAction<{ id: EntityId; isInTestMode: boolean }>
    ) {
      const { id, isInTestMode } = payload.payload;
      const collector = state.entities[id];
      if (collector) {
        collector.isInTestMode = isInTestMode;
      }
    },
  },
  extraReducers: {
    [LOCATION_CHANGE]: (state) => {
      state.searchResultsIds = null;
    },
  },
});

export interface UpdateTestMode {
  isInTestMode: boolean;
}

const CACHE_KEY_COLLECTORS = "Collector";

export const collectorsApi = createApi({
  reducerPath: "collectorApi",

  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(""),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),

  tagTypes: [CACHE_KEY_COLLECTORS],

  endpoints: (builder) => ({
    updateTestMode: builder.mutation<
      UpdateTestMode,
      UpdateTestMode & { id: EntityId }
    >({
      query: ({ id, ...body }) => ({
        url: `/admin/box/${id}/isInTestMode`,
        method: "PATCH",
        body,
      }),
      async onQueryStarted(baseData, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            collectorsSlice.actions.updateCollectorMode({
              id: baseData.id,
              isInTestMode: data.isInTestMode,
            })
          );
        } catch (err) {
          throw err;
        }
      },
    }),
  }),
});

export const { useUpdateTestModeMutation } = collectorsApi;

export default collectorsSlice.reducer;
