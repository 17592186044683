import { EntityId } from "@reduxjs/toolkit";

// Constants
import { API_ENDPOINT } from "./constants";

// Types
import ItemType from "types/entities/itemType";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";

const baseUrl = getApiUrl(API_ENDPOINT);

export const create = (params: { entity: ItemType; token: string }) =>
  fetch<ItemType>(`${baseUrl}`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });

export const fetchAll = (params: { token: string }) =>
  fetch<ItemType[]>(baseUrl, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { id: EntityId; token: string }) =>
  fetch<ItemType>(`${baseUrl}/${params.id}`, {
    method: "GET",
    token: params.token,
  });

export const update = (params: { entity: ItemType; token: string }) =>
  fetch<ItemType>(`${baseUrl}/${params.entity.id}`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });
