import { combineEpics } from "redux-observable";

// Epics
import createEpic from "./epics/createEpic";
import fetchAllEpic from "./epics/fetchAllEpic";
import fetchByIdEpic from "./epics/fetchByIdEpic";
import searchEpic from "./epics/searchEpic";
import updateEpic from "./epics/updateEpic";

const epic = combineEpics(
  createEpic,
  fetchAllEpic,
  fetchByIdEpic,
  searchEpic,
  updateEpic
);

export default epic;
