import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import CollectorType from "types/entities/collectorType";

// Utils
import { createBaseReducers } from "store/utils/reducers";

export const collectorTypesAdapter = createEntityAdapter<CollectorType>();

export const collectorTypesSlice = createSlice({
  name: SLICE_NAME,
  initialState: collectorTypesAdapter.getInitialState(INITIAL_STATE),
  reducers: createBaseReducers<CollectorType>(collectorTypesAdapter),
});

export default collectorTypesSlice.reducer;
