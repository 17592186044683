import { BaseState, StatusAction } from "../types";

export const updateErrorState = (state: BaseState, error: string) => {
  state.error = error;
  state.status = "ERROR";
};

export const updatePendingState = (
  state: BaseState,
  statusAction: Nullable<StatusAction> = null
) => {
  state.error = null;
  state.status = "PENDING";
  state.statusAction = statusAction;
};

export const updateSuccessState = (
  state: BaseState,
  statusAction: Nullable<StatusAction> = null
) => {
  state.error = null;
  state.status = "SUCCESS";
  state.statusAction = statusAction;
};
