import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Hooks
import useAuth from "modules/auth/useAuth";

// Pages
import LoadingPage from "pages/Loading/LoadingPage";

// Utils
import getRoutePath from "modules/routing/getRoutePath";
import { RouteData } from "modules/routing/types";

type Props = RouteProps & RouteData;

const PrivateRoute: FC<Props> = (props) => {
  const { isAuthenticated, isPending } = useAuth();

  if (isAuthenticated) {
    return <Route {...props} />;
  } else if (isPending) {
    return <LoadingPage />;
  } else {
    return <Redirect to={getRoutePath(ROUTES_NAME.LOGIN)} />;
  }
};

export default PrivateRoute;
