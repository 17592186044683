import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as restaurantsApi from "api/restaurants/restaurantsApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { restaurantsSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import Restaurant from "types/entities/restaurant";

const searchSuccessHandler = ({
  data: { count, results: entities },
}: FetchResponse<{
  count: number;
  results: Restaurant[];
}>) =>
  of(
    restaurantsSlice.actions.searchSuccess({
      count,
      entities,
    })
  );

const searchErrorHandler = (error: Error) =>
  of(
    restaurantsSlice.actions.searchError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la recherche de restaurant(s).",
    })
  );

const searchEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof restaurantsSlice.actions.search>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(restaurantsSlice.actions.search.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        restaurantsApi.search({
          filter: payload.filter,
          offset: payload.offset,
          token,
        })
      ).pipe(
        switchMap(searchSuccessHandler),
        catchError(searchErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default searchEpic;
