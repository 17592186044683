import {
  RawRecreditMean,
  RecreditMeanDTO,
} from "api/sites/boxTypesV3Configurations/boxTypesV3Configurations.type";

export const mapRecreditMenDTO2Raw = (
  recreditMeans: RecreditMeanDTO[]
): RawRecreditMean => recreditMeans.join("_") as RawRecreditMean;

export const mapRawRecreditMean2DTO = (
  recreditMean: RawRecreditMean
): RecreditMeanDTO[] => recreditMean.split("_") as RecreditMeanDTO[];
