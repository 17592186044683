import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as authApi from "api/auth/authApi";

// Constants
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from "modules/auth/constants";

// Selectors
import { currentUserRefreshTokenSelector } from "../selectors";

// Slices
import { currentUserSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import User from "types/entities/user";

const refreshTokenSuccessHandler = ({ headers }: FetchResponse<User>) => {
  const token = headers.get(TOKEN_KEY);
  const refreshToken = headers.get(REFRESH_TOKEN_KEY);

  return of(
    currentUserSlice.actions.refreshTokenSuccess({ refreshToken, token })
  );
};

const refreshTokenErrorHandler = (error: Error) =>
  of(currentUserSlice.actions.refreshTokenError({ error: error.message }));

const refreshTokenEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof currentUserSlice.actions.refreshToken>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(currentUserSlice.actions.refreshToken.type)),
    switchMap(() => {
      const refreshToken = currentUserRefreshTokenSelector(
        state$.value
      ) as string;

      return from(authApi.refreshToken({ refreshToken })).pipe(
        switchMap(refreshTokenSuccessHandler),
        catchError(refreshTokenErrorHandler)
      );
    })
  );

export default refreshTokenEpic;
