export enum ROUTES_NAME {
  HOME = "home",
  LOGIN = "login",
  // Sites
  SITES = "sites",
  SITES_READ = "sitesRead",
  SITES_CREATE = "sitesCreate",
  SITES_EDIT = "sitesEdit",
  SITES_DUPLICATE = "sitesDuplicate",
  // Site Networks
  SITE_NETWORKS = "siteNetworks",
  SITE_NETWORKS_READ = "siteNetworksRead",
  SITE_NETWORKS_CREATE = "siteNetworksCreate",
  // Games Configuration
  GAMES_CONFIG = "gamesConfiguration",
  // Client accesses
  CLIENT_ACCESSES = "clientAccesses",
  CLIENT_ACCESSES_READ = "clientAccessesRead",
  CLIENT_ACCESSES_CREATE = "clientAccessesCreate",
  CLIENT_ACCESSES_EDIT = "clientAccessesEdit",
  // Collectors
  COLLECTORS = "collectors",
  COLLECTORS_READ = "collectorsRead",
  COLLECTORS_CREATE = "collectorsCreate",
  COLLECTORS_EDIT = "collectorsEdit",
  COLLECTORS_DUPLICATE = "collectorsDuplicate",
  // Collector types
  COLLECTOR_TYPES = "collectorsTypes",
  COLLECTOR_TYPES_READ = "collectorsTypesRead",
  COLLECTOR_TYPES_CREATE = "collectorsTypesCreate",
  COLLECTOR_TYPES_EDIT = "collectorsTypesEdit",
  // Items
  ITEMS = "items",
  ITEMS_READ = "itemsRead",
  ITEMS_CREATE = "itemsCreate",
  ITEMS_EDIT = "itemsEdit",
  // Item types
  ITEM_TYPES = "itemTypes",
  ITEM_TYPES_READ = "itemTypesRead",
  ITEM_TYPES_CREATE = "itemTypesCreate",
  ITEM_TYPES_EDIT = "itemTypesEdit",
  // Item categories
  ITEM_CATEGORIES = "itemCategories",
  ITEM_CATEGORIES_READ = "itemCategoriesRead",
  ITEM_CATEGORIES_CREATE = "itemCategoriesCreate",
  ITEM_CATEGORIES_EDIT = "itemCategoriesEdit",
  // User password reset interface
  RESET_PASSWORD = "resetPassword",
  // Points of sales
  POINTS_OF_SALES = "pointsOfSales",
  POINTS_OF_SALES_READ = "pointsOfSalesRead",
  POINTS_OF_SALES_CREATE = "pointsOfSalesCreate",
  POINTS_OF_SALES_EDIT = "pointsOfSalesEdit",
  // Pos machines
  POS_MACHINES = "posMachines",
  POS_MACHINES_READ = "posMachinesRead",
  POS_MACHINES_CREATE = "posMachinesCreate",
  POS_MACHINES_EDIT = "posMachinesEdit",
  // Restaurant owners
  RESTAURATEURS = "restaurateurs",
  RESTAURATEURS_READ = "restaurantOwnersRead",
  RESTAURATEURS_CREATE = "restaurantOwnersCreate",
  RESTAURATEURS_EDIT = "restaurantOwnersEdit",
  // Restaurants
  RESTAURANTS = "restaurants",
  RESTAURANTS_READ = "restaurantsRead",
  RESTAURANTS_CREATE = "restaurantsCreate",
  RESTAURANTS_EDIT = "restaurantsEdit",
  // Tech providers
  TECH_PROVIDERS = "techProviders",
  TECH_PROVIDERS_READ = "techProvidersRead",
  TECH_PROVIDERS_CREATE = "techProvidersCreate",
  TECH_PROVIDERS_EDIT = "techProvidersEdit",
  // Partners
  PARTNERS = "partners",
  PARTNERS_READ = "partnersRead",
  PARTNERS_CREATE = "partnersCreate",
  PARTNERS_EDIT = "partnersEdit",
  // Users
  USERS = "users",
  USERS_READ = "usersRead",
  USERS_EDIT = "usersEdit",
  // User Recredit
  USER_RECREDIT = "usersRecredit",
  // Leasing Journey configuration
  LEASING_AGREEMENT_CONFIGURATION = "leasingAgreementConfiguration",
  LEASING_DISTRIBUTION_CONFIGURATION = "leasingDistributionConfiguration",
  LEASING_PAYMENT_CONFIGURATION = "leasingPaymentConfiguration",
  // Partner API access
  PARTNER_API_ACCESS_SITE = "partnerApiAccessSite",
  // Gestion du retour
  RETURN_MANAGEMENT = "returnManagement",
}

export const ROUTES_PATH = {
  [ROUTES_NAME.HOME]: "/home",
  [ROUTES_NAME.LOGIN]: "/login",
  // Sites
  [ROUTES_NAME.SITES]: "/sites",
  [ROUTES_NAME.SITES_READ]: "/sites/:id",
  [ROUTES_NAME.SITES_CREATE]: "/sites/new",
  [ROUTES_NAME.SITES_EDIT]: "/sites/:id/edit",
  [ROUTES_NAME.SITES_DUPLICATE]: "/sites/:id/duplicate",
  // Site Networks
  [ROUTES_NAME.SITE_NETWORKS]: "/site_networks",
  [ROUTES_NAME.SITE_NETWORKS_READ]: "/site_networks/:id",
  [ROUTES_NAME.SITE_NETWORKS_CREATE]: "/site_networks/new",
  // Games Configuration
  [ROUTES_NAME.GAMES_CONFIG]: "/sites/:id/games",
  // Client accesses
  [ROUTES_NAME.CLIENT_ACCESSES]: "/client_accesses",
  [ROUTES_NAME.CLIENT_ACCESSES_READ]: "/client_accesses/:id",
  [ROUTES_NAME.CLIENT_ACCESSES_CREATE]: "/client_accesses/new",
  [ROUTES_NAME.CLIENT_ACCESSES_EDIT]: "/client_accesses/:id/edit",
  // Collectors
  [ROUTES_NAME.COLLECTORS]: "/machines",
  [ROUTES_NAME.COLLECTORS_READ]: "/machines/:id",
  [ROUTES_NAME.COLLECTORS_CREATE]: "/machines/new",
  [ROUTES_NAME.COLLECTORS_EDIT]: "/machines/:id/edit",
  [ROUTES_NAME.COLLECTORS_DUPLICATE]: "/machines/:id/duplicate",
  // Collector types
  [ROUTES_NAME.COLLECTOR_TYPES]: "/collector_types",
  [ROUTES_NAME.COLLECTOR_TYPES_READ]: "/collector_types/:id",
  [ROUTES_NAME.COLLECTOR_TYPES_CREATE]: "/collector_types/new",
  [ROUTES_NAME.COLLECTOR_TYPES_EDIT]: "/collector_types/:id/edit",
  // Items
  [ROUTES_NAME.ITEMS]: "/contenants",
  [ROUTES_NAME.ITEMS_READ]: "/contenants/:id",
  [ROUTES_NAME.ITEMS_CREATE]: "/contenants/new",
  [ROUTES_NAME.ITEMS_EDIT]: "/contenants/:id/edit",
  // Item types
  [ROUTES_NAME.ITEM_TYPES]: "/types_de_contenant",
  [ROUTES_NAME.ITEM_TYPES_READ]: "/types_de_contenant/:id",
  [ROUTES_NAME.ITEM_TYPES_CREATE]: "/types_de_contenant/new",
  [ROUTES_NAME.ITEM_TYPES_EDIT]: "/types_de_contenant/:id/edit",
  // Item categories
  [ROUTES_NAME.ITEM_CATEGORIES]: "/categories_de_contenant",
  [ROUTES_NAME.ITEM_CATEGORIES_READ]: "/categories_de_contenant/:id",
  [ROUTES_NAME.ITEM_CATEGORIES_CREATE]: "/categories_de_contenant/new",
  [ROUTES_NAME.ITEM_CATEGORIES_EDIT]: "/categories_de_contenant/:id/edit",
  // User password reset
  [ROUTES_NAME.RESET_PASSWORD]: "/reinitialisation-mot-de-passe",
  // Points of sales
  [ROUTES_NAME.POINTS_OF_SALES]: "/points_de_collecte",
  [ROUTES_NAME.POINTS_OF_SALES_READ]: "/points_de_collecte/:id",
  [ROUTES_NAME.POINTS_OF_SALES_CREATE]: "/points_de_collecte/new",
  [ROUTES_NAME.POINTS_OF_SALES_EDIT]: "/points_de_collecte/:id/edit",
  // Pos machines
  [ROUTES_NAME.POS_MACHINES]: "/pos_machines",
  [ROUTES_NAME.POS_MACHINES_READ]: "/pos_machines/:id",
  [ROUTES_NAME.POS_MACHINES_CREATE]: "/pos_machines/new",
  [ROUTES_NAME.POS_MACHINES_EDIT]: "/pos_machines/:id/edit",
  // Restaurant owners
  [ROUTES_NAME.RESTAURATEURS]: "/restaurateurs",
  [ROUTES_NAME.RESTAURATEURS_READ]: "/restaurateurs/:id",
  [ROUTES_NAME.RESTAURATEURS_CREATE]: "/restaurateurs/new",
  [ROUTES_NAME.RESTAURATEURS_EDIT]: "/restaurateurs/:id/edit",
  // Restaurants
  [ROUTES_NAME.RESTAURANTS]: "/restaurants",
  [ROUTES_NAME.RESTAURANTS_READ]: "/restaurants/:id",
  [ROUTES_NAME.RESTAURANTS_CREATE]: "/restaurants/new",
  [ROUTES_NAME.RESTAURANTS_EDIT]: "/restaurants/:id/edit",
  // Tech providers
  [ROUTES_NAME.TECH_PROVIDERS]: "/tech_providers",
  [ROUTES_NAME.TECH_PROVIDERS_READ]: "/tech_providers/:id",
  [ROUTES_NAME.TECH_PROVIDERS_CREATE]: "/tech_providers/new",
  [ROUTES_NAME.TECH_PROVIDERS_EDIT]: "/tech_providers/:id/edit",
  // Partners
  [ROUTES_NAME.PARTNERS]: "/partenaires",
  [ROUTES_NAME.PARTNERS_READ]: "/partenaires/:id",
  [ROUTES_NAME.PARTNERS_CREATE]: "/partenaires/new",
  [ROUTES_NAME.PARTNERS_EDIT]: "/partenaires/:id/edit",
  // Users
  [ROUTES_NAME.USERS]: "/utilisateurs",
  [ROUTES_NAME.USERS_READ]: "/utilisateurs/:id",
  [ROUTES_NAME.USERS_EDIT]: "/utilisateurs/:id/edit",
  // User Recredit
  [ROUTES_NAME.USER_RECREDIT]: "/recredit_users",
  // Leasing Agreement Configuration
  [ROUTES_NAME.LEASING_AGREEMENT_CONFIGURATION]: "/sites/:id/contrat",
  [ROUTES_NAME.LEASING_DISTRIBUTION_CONFIGURATION]: "/sites/:id/distribution",
  [ROUTES_NAME.LEASING_PAYMENT_CONFIGURATION]: "/sites/:id/paiement",
  // Partner API access
  [ROUTES_NAME.PARTNER_API_ACCESS_SITE]: "/sites/:id/acces-api-partenaire",
  // Gestion du retour
  [ROUTES_NAME.RETURN_MANAGEMENT]: "/sites/:id/gestion-du-retour",
} as const;

export const DEFAULT_ROUTE_NAME =
  (process.env.REACT_APP_DEFAULT_ROUTE_NAME as ROUTES_NAME) || ROUTES_NAME.HOME;
