import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getApiUrl from "modules/api/getApiUrl";
import { RootState } from "store";
import { currentUserTokenSelector } from "store/features/currentUser/selectors";
import { toUrlEncoded } from "store/utils/toURLEncoded";

const CACHE_KEY_PARTNER_API_ACCESS = "PaymentSystemConfiguration";

export const partnerApiAccessApi = createApi({
  reducerPath: "PartnerApiAccessApi",

  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(""),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),

  tagTypes: [CACHE_KEY_PARTNER_API_ACCESS],

  endpoints: (builder) => ({
    getPartnerApiAccess: builder.query<
      PartnerApiAccess[],
      PartnerApiAccessSearch
    >({
      query: (params) =>
        `/admin/authenticate/partnerApiAccess?${toUrlEncoded(params)}`,
      providesTags: (_result, _error) => [
        { type: CACHE_KEY_PARTNER_API_ACCESS },
      ],
    }),
    getPartnerApiAccessById: builder.query<PartnerApiAccess, number>({
      query: (id) => `/admin/authenticate/partnerApiAccess/${id}`,
      providesTags: (_result, _error) => [
        { type: CACHE_KEY_PARTNER_API_ACCESS },
      ],
    }),

    updatePartnerApiAccess: builder.mutation<
      PartnerApiAccess,
      AdminPartnerApiAccessEditable
    >({
      query: ({ id, ...params }) => ({
        url: `/admin/authenticate/partnerApiAccess/${id}`,
        method: "PUT",
        body: params,
      }),
    }),
    createPartnerApiAccess: builder.mutation<
      CreatePartnerApiAccessResult,
      AdminPartnerApiAccessCreate
    >({
      query: (params) => ({
        url: `/admin/authenticate/partnerApiAccess`,
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const {
  useGetPartnerApiAccessQuery,
  useGetPartnerApiAccessByIdQuery,
  useUpdatePartnerApiAccessMutation,
  useCreatePartnerApiAccessMutation,
} = partnerApiAccessApi;

export interface PartnerApiAccess {
  id: number;
  name: string;
  description: string;
  distributorRef: number;
  distributorType: "PrivateCatering" | "RestaurantNetwork";
  apiKey: string;
  isActive: boolean;
  partnerId: number;
}

export type PartnerApiAccessSearch = Partial<
  Pick<
    PartnerApiAccess,
    "distributorRef" | "distributorType" | "isActive" | "partnerId"
  >
>;

export type AdminPartnerApiAccessEditable = {
  id: number;
  isActive: boolean;
  name: string;
  description: string;
  partnerId: number;
};

export type AdminPartnerApiAccessCreate = {
  name: string;
  description: string;
  distributorType: "PrivateCatering" | "RestaurantNetwork";
  distributorRef: number;
  isActive: boolean;
  partnerId: number;
};

export interface CreatePartnerApiAccessResult {
  credentials: {
    apiKey: string;
    apiSecret: string;
  };
  partnerApiAccess: {
    id: number;
  };
}
