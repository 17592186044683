import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import User from "types/entities/user";

export const currentUserSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    getProfile: (state) => {
      state.getProfileError = null;
      state.getProfileStatus = "PENDING";
    },
    getProfileError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.getProfileError = payload.error;
      state.getProfileStatus = "ERROR";
    },
    getProfileSuccess: (state, { payload }: PayloadAction<{ user: User }>) => {
      state.id = payload.user.id;
      state.name = payload.user.name;
      state.getProfileStatus = "SUCCESS";
    },
    login: (
      state,
      _action: PayloadAction<{ email: string; password: string }>
    ) => {
      state.loginError = null;
      state.loginStatus = "PENDING";
    },
    loginError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.loginError = payload.error;
      state.loginStatus = "ERROR";
    },
    loginSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        refreshToken: Nullable<string>;
        token: Nullable<string>;
        user: User;
      }>
    ) => {
      state.id = payload.user.id;
      state.loginStatus = "SUCCESS";
      state.name = payload.user.name;
      state.refreshToken = payload.refreshToken;
      state.token = payload.token;
    },
    logout: () => INITIAL_STATE,
    refreshToken: (state) => {
      state.refreshTokenStatus = "PENDING";
    },
    refreshTokenError: (
      state,
      { payload }: PayloadAction<{ error: string }>
    ) => {
      state.refreshTokenError = payload.error;
      state.refreshTokenStatus = "ERROR";
    },
    refreshTokenSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        refreshToken: Nullable<string>;
        token: Nullable<string>;
      }>
    ) => {
      state.refreshToken = payload.refreshToken;
      state.refreshTokenStatus = "SUCCESS";
      state.token = payload.token;
    },
  },
});

export default currentUserSlice.reducer;
