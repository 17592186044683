// Constants
import { API_ENDPOINT } from "./constants";

// Types
import TransactionOut, {
  TransactionOutRequest,
} from "types/entities/transactionOut";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";

const baseUrl = getApiUrl(API_ENDPOINT);

export const create = (params: {
  entity: TransactionOutRequest;
  token: string;
}) =>
  fetch<{ transaction: TransactionOut }>(baseUrl, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });

export const fetchAll = (params: { token: string }) =>
  fetch<TransactionOut[]>(`${baseUrl}/last`, {
    method: "GET",
    token: params.token,
  });
