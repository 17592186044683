import { EntityId } from "@reduxjs/toolkit";

// Constants
import { API_ENDPOINT } from "./constants";

// Types
import Restaurateur, {
  RestaurantOwnerRequest,
} from "types/entities/restaurateur";

// Utils
import fetch from "modules/api/fetch";
import getRestaurateurApiUrl from "modules/api/getRestaurateurApiUrl";

const baseUrl = getRestaurateurApiUrl(API_ENDPOINT);

export const create = (params: {
  entity: RestaurantOwnerRequest;
  token: string;
}) =>
  fetch<Restaurateur>(baseUrl, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });

export const fetchAll = (params: { token: string }) =>
  fetch<{
    count: number;
    results: Restaurateur[];
  }>(baseUrl, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { id: EntityId; token: string }) =>
  fetch<Restaurateur>(`${baseUrl}/${params.id}`, {
    method: "GET",
    token: params.token,
  });

export const search = (params: {
  filter?: string;
  offset?: number;
  token: string;
}) => {
  const searchParams = new URLSearchParams();

  if (params.filter) {
    searchParams.append("filter", params.filter);
  }

  if (params.offset) {
    searchParams.append("offset", String(params.offset));
  }

  return fetch<{
    count: number;
    results: Restaurateur[];
  }>(`${baseUrl}?${searchParams.toString()}`, {
    method: "GET",
    token: params.token,
  });
};

export const update = (params: {
  entity: RestaurantOwnerRequest;
  token: string;
}) =>
  fetch<Restaurateur>(`${baseUrl}/${params.entity.id}`, {
    body: JSON.stringify(params.entity),
    method: "PATCH",
    token: params.token,
  });
