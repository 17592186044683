import { EntityId } from "@reduxjs/toolkit";
import PosMachine from "types/entities/posMachine";

export type PosMachineCreation = Omit<PosMachine, "BoxType">;

export type PosMachineUpdate = {
  BoxTypeId: EntityId;
  description: string;
  isActive: boolean;
  name: string;
  TechProviderId: EntityId;
};

export const normalizedPosMachineForUpdate = ({
  BoxType,
  machineId,
  id,
  ...posMachine
}: PosMachine): PosMachineUpdate => posMachine;

export const normalizedPosMachineForCreation = ({
  BoxType,
  ...posMachine
}: PosMachine): PosMachineCreation => posMachine;
