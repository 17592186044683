import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as transactionsOutApi from "api/transactionsOut/transactionsOutApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { lastTransactionsOutSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import TransactionOut from "types/entities/transactionOut";

const fetchAllEpicSuccessHandler = ({
  data: entities,
}: FetchResponse<TransactionOut[]>) =>
  of(
    lastTransactionsOutSlice.actions.fetchAllSuccess({
      entities,
    })
  );

const fetchAllEpicErrorHandler = (error: Error) =>
  of(
    lastTransactionsOutSlice.actions.fetchAllError({
      error: error.message,
    })
  );

const fetchAllEpicEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof lastTransactionsOutSlice.actions.fetchAll>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(lastTransactionsOutSlice.actions.fetchAll.type)),
    switchMap(() => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(transactionsOutApi.fetchAll({ token })).pipe(
        switchMap(fetchAllEpicSuccessHandler),
        catchError(fetchAllEpicErrorHandler)
      );
    })
  );

export default fetchAllEpicEpic;
