export enum NotificationType {
  ERROR = "error",
  SUCCESS = "success",
}

export type InitialState = {
  message: Nullable<string>;
  namespace?: string;
  shouldCleanOnRouteChange: boolean;
  title?: Nullable<string>;
  type: Nullable<NotificationType>;
};
