import { ActionsObservable, Epic } from "redux-observable";
import { EMPTY, of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { appSlice } from "../slice";
import { currentUserSlice } from "store/features/currentUser/slice";

const initializeEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof appSlice.actions.initialize>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(appSlice.actions.initialize.type)),
    switchMap(() => {
      const token = currentUserTokenSelector(state$.value);

      if (token) {
        return of(currentUserSlice.actions.getProfile());
      } else {
        return EMPTY;
      }
    })
  );

export default initializeEpic;
