// Types
import { InitialState } from "./types";

export const SLICE_NAME = "app";

export const INITIAL_STATE: InitialState = {
  error: null,
  isInitialized: false,
  status: "INITIAL",
  statusAction: null,
};
