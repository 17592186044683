import Partners from "types/entities/partners";

export type PartnersWithoutParanoid = Pick<
  Partners,
  "id" | "name" | "contact" | "role" | "email" | "phone" | "description"
>;

export const cleanPartners = (partner: any): PartnersWithoutParanoid => {
  const { createdAt, updatedAt, deletedAt, ...cleanedPartner } = partner;
  return cleanedPartner;
};
