import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as transactionsInApi from "api/transactionsIn/transactionsInApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { lastTransactionsInSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import TransactionIn from "types/entities/transactionIn";

const fetchAllEpicSuccessHandler = ({
  data: entities,
}: FetchResponse<TransactionIn[]>) =>
  of(
    lastTransactionsInSlice.actions.fetchAllSuccess({
      entities,
    })
  );

const fetchAllEpicErrorHandler = (error: Error) =>
  of(
    lastTransactionsInSlice.actions.fetchAllError({
      error: error.message,
    })
  );

const fetchAllEpicEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof lastTransactionsInSlice.actions.fetchAll>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(lastTransactionsInSlice.actions.fetchAll.type)),
    switchMap(() => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(transactionsInApi.fetchAll({ token })).pipe(
        switchMap(fetchAllEpicSuccessHandler),
        catchError(fetchAllEpicErrorHandler)
      );
    })
  );

export default fetchAllEpicEpic;
