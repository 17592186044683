import jwt_decode from "jwt-decode";

// Types
import { DecodedToken } from "./types";

const getTokenRemainingTime = (token: string) => {
  const { exp } = jwt_decode<DecodedToken>(token);

  return exp - Date.now() / 1000;
};

export default getTokenRemainingTime;
