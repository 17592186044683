import { createSelector, EntityId } from "@reduxjs/toolkit";

// Selectors
import { transactionsByUserSelector } from "../transactionsByUser/selectors";

// Slices
import { usersAdapter } from "./slice";

// Store
import { RootState } from "store";

// Types
import BoxType from "types/entities/boxType";
import User from "types/entities/user";

export const usersStateSelector = (state: RootState) => state.users;

export const usersSelectors = usersAdapter.getSelectors<RootState>(
  (state) => state.users
);

export const usersSearchResultsIdsSelectors = createSelector(
  usersStateSelector,
  (users) => users.searchResultsIds
);

export const usersSearchResultsSelectors = createSelector(
  usersSearchResultsIdsSelectors,
  usersSelectors.selectEntities,
  (ids, entities) =>
    ids
      ? ids.reduce<User[]>((acc, id) => {
          const user = entities[id];

          return user ? [...acc, user] : acc;
        }, [])
      : undefined
);

export const usersSelector = createSelector(
  usersSelectors.selectAll,
  usersSearchResultsSelectors,
  (users, usersSearchResults) => usersSearchResults || users
);

export const isPendingSelector = createSelector(
  usersStateSelector,
  ({ status }) => status === "PENDING"
);

export const activeSitesByUserSelector = (userId: EntityId) =>
  createSelector(transactionsByUserSelector(userId), (transactions) => {
    const cash: Record<
      EntityId,
      {
        boxType: BoxType;
        value: number;
      }
    > = {};

    transactions?.incomingTransactions.forEach((transaction) => {
      const boxType = transaction.box?.BoxTypes[0];
      const id = boxType.id;

      if (!boxType) {
        return;
      }

      if (cash[id] === undefined) {
        cash[id] = {
          boxType: boxType,
          value: transaction.value,
        };
      } else {
        cash[id].value = cash[id].value + transaction.value;
      }
    });

    transactions?.outgoingTransactions.forEach((transaction) => {
      const boxType = transaction.boxType;
      const id = boxType.id;

      if (!boxType) {
        return;
      }

      if (cash[id] === undefined) {
        cash[id] = {
          boxType: boxType,
          value: -transaction.value,
        };
      } else {
        cash[id].value = cash[id].value - transaction.value;
      }
    });

    return Object.values(cash);
  });
