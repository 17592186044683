import { ActionsObservable, Epic } from "redux-observable";
import { push } from "connected-react-router";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as clientAccessesApi from "api/clientAccesses/clientAccessesApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { clientAccessesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import ClientAccess from "types/entities/clientAccess";

// Utils
import getEntityRoutePath from "modules/routing/getEntityRoutePath";

const updateSuccessHandler = ({ data: entity }: FetchResponse<ClientAccess>) =>
  of(
    clientAccessesSlice.actions.updateSuccess({
      entity,
    }),
    clientAccessesSlice.actions.fetchById({ id: entity.id }),
    push(getEntityRoutePath(entity.id, ROUTES_NAME.CLIENT_ACCESSES_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "Le client access a été mis à jour avec succès.",
    })
  );

const updateErrorHandler = (error: Error) =>
  of(
    clientAccessesSlice.actions.updateError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la mise à jour du client access.",
    })
  );

const updateEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof clientAccessesSlice.actions.update>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(clientAccessesSlice.actions.update.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        clientAccessesApi.update({ entity: payload.entity, token })
      ).pipe(
        switchMap(updateSuccessHandler),
        catchError(updateErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default updateEpic;
