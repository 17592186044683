import { push } from "connected-react-router";
import { ActionsObservable } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as authApi from "api/auth/authApi";

// Constants
import { DEFAULT_ROUTE_NAME } from "modules/routing/constants";
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from "modules/auth/constants";

// Slices
import { currentUserSlice } from "../slice";
import { notificationSlice } from "store/features/notification/slice";

// Types
import { FetchResponse } from "modules/api/types";
import User from "types/entities/user";

// Utils
import getRoutePath from "modules/routing/getRoutePath";

const loginSuccessHandler = ({ data: user, headers }: FetchResponse<User>) => {
  const token = headers.get(TOKEN_KEY);
  const refreshToken = headers.get(REFRESH_TOKEN_KEY);

  return of(
    currentUserSlice.actions.loginSuccess({ refreshToken, token, user }),
    push(getRoutePath(DEFAULT_ROUTE_NAME))
  );
};

const loginErrorHandler = (error: Error) =>
  of(
    currentUserSlice.actions.loginError({ error: error.message }),
    notificationSlice.actions.addErrorNotification({
      message: "Combinaison email/mot de passe incorrect",
    })
  );

const loginEpic = (
  action$: ActionsObservable<ActionType<typeof currentUserSlice.actions.login>>
) =>
  action$.pipe(
    filter(isOfType(currentUserSlice.actions.login.type)),
    switchMap(({ payload }) =>
      from(authApi.login(payload)).pipe(
        switchMap(loginSuccessHandler),
        catchError(loginErrorHandler),
        startWith(notificationSlice.actions.clean())
      )
    )
  );

export default loginEpic;
