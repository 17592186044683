import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import Restaurant, { RestaurantRequest } from "types/entities/restaurant";

// Utils
import {
  createBaseReducers,
  createReducer,
  createSearchReducers,
  updateReducer,
} from "store/utils/reducers";
import { updateSuccessState } from "store/utils/stateUpdater";

export const restaurantsAdapter = createEntityAdapter<Restaurant>();

const { create, fetchAllSuccess, update, ...baseReducers } =
  createBaseReducers<Restaurant>(restaurantsAdapter);

export const restaurantsSlice = createSlice({
  name: SLICE_NAME,
  initialState: restaurantsAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    ...baseReducers,
    ...createSearchReducers<Restaurant>(restaurantsAdapter),
    create: createReducer<Restaurant, { entity: RestaurantRequest }>(),
    fetchAllSuccess: (
      state,
      { payload }: PayloadAction<{ count: number; entities: Restaurant[] }>
    ) => {
      state.count = payload.count;

      restaurantsAdapter.setAll(state, payload.entities);
      updateSuccessState(state);
    },
    update: updateReducer<Restaurant, { entity: RestaurantRequest }>(),
  },
  extraReducers: {
    [LOCATION_CHANGE]: (state) => {
      state.searchResultsIds = null;
    },
  },
});

export default restaurantsSlice.reducer;
