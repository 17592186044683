import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import TransactionIn from "types/entities/transactionIn";

// Utils
import { createBaseReducers } from "store/utils/reducers";

export const lastTransactionsInAdapter = createEntityAdapter<TransactionIn>();

const {
  fetchAll,
  fetchAllError,
  fetchAllSuccess,
} = createBaseReducers<TransactionIn>(lastTransactionsInAdapter);

export const lastTransactionsInSlice = createSlice({
  name: SLICE_NAME,
  initialState: lastTransactionsInAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    fetchAll,
    fetchAllError,
    fetchAllSuccess,
  },
});

export default lastTransactionsInSlice.reducer;
