import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as itemsApi from "api/items/itemsApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { itemsSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import Item from "types/entities/item";

const searchSuccessHandler = ({
  data: { count, items: entities },
}: FetchResponse<{
  count: number;
  items: Item[];
}>) =>
  of(
    itemsSlice.actions.searchSuccess({
      count,
      entities,
    })
  );

const searchErrorHandler = (error: Error) =>
  of(
    itemsSlice.actions.searchError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la recherche de contenant(s).",
    })
  );

const searchEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof itemsSlice.actions.search>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(itemsSlice.actions.search.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        itemsApi.search({
          filter: payload.filter,
          offset: payload.offset,
          token,
        })
      ).pipe(
        switchMap(searchSuccessHandler),
        catchError(searchErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default searchEpic;
