import { EntityId } from "@reduxjs/toolkit";
// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";
// Types
import PointOfSale from "types/entities/pointOfSale";
// Constants
import { API_ENDPOINT } from "./constants";

const baseUrl = getApiUrl(API_ENDPOINT);

export const create = (params: { entity: PointOfSale; token: string }) =>
  fetch<PointOfSale>(`${baseUrl}/new`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });

export const fetchAll = (params: { token: string }) =>
  fetch<{
    count: number;
    results: PointOfSale[];
  }>(baseUrl, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { id: EntityId; token: string }) =>
  fetch<PointOfSale>(`${baseUrl}/${params.id}`, {
    method: "GET",
    token: params.token,
  });

export const search = (params: {
  filter?: string;
  offset?: number;
  token: string;
}) => {
  const searchParams = new URLSearchParams();

  if (params.filter) {
    searchParams.append("filter", params.filter);
  }

  if (params.offset) {
    searchParams.append("offset", String(params.offset));
  }

  return fetch<{
    count: number;
    results: PointOfSale[];
  }>(`${baseUrl}?${searchParams.toString()}`, {
    method: "GET",
    token: params.token,
  });
};

export const update = (params: { entity: PointOfSale; token: string }) =>
  fetch<PointOfSale>(`${baseUrl}/${params.entity.id}`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });
