import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import TransactionOut, {
  TransactionOutRequest,
} from "types/entities/transactionOut";

// Utils
import {
  createReducer,
  createSuccessReducer,
  errorReducer,
} from "store/utils/reducers";

export const transactionsOutAdapter = createEntityAdapter<TransactionOut>();

export const transactionsOutSlice = createSlice({
  name: SLICE_NAME,
  initialState: transactionsOutAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    create: createReducer<TransactionOut, { entity: TransactionOutRequest }>(),
    createError: errorReducer<TransactionOut>(),
    createSuccess: createSuccessReducer<TransactionOut>(transactionsOutAdapter),
  },
});

export default transactionsOutSlice.reducer;
