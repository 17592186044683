import { lazy } from "react";

// This function loads page dynamically, using React.lazy(),
// ands adds some delay to have a smooth transition.
const lazyLoadPage = (pagePath: string) =>
  lazy(() =>
    Promise.all<{
      default: React.ComponentType;
    }>([
      import(`pages/${pagePath}`),
      new Promise((resolve) => setTimeout(resolve, 300)),
    ]).then(([moduleExports]) => moduleExports)
  );

export default lazyLoadPage;
