import {
  EntityAdapter,
  EntityId,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";

// Types
import { BaseState, SearchState } from "store/types";
import { BaseEntity } from "types/common";

// Utils
import {
  updateErrorState,
  updatePendingState,
  updateSuccessState,
} from "./stateUpdater";

export const createBaseReducers = <T>(entityAdapter: EntityAdapter<T>) => ({
  // Create
  create: createReducer<T, { entity: T }>(),
  createError: errorReducer<T>(),
  createSuccess: createSuccessReducer<T>(entityAdapter),

  // Fetch all
  fetchAll: fetchAllReducer<T>(),
  fetchAllError: errorReducer<T>(),
  fetchAllSuccess: fetchAllSuccessReducer<T>(entityAdapter),

  // Fetch by id
  fetchById: fetchByIdReducer<T>(),
  fetchByIdError: errorReducer<T>(),
  fetchByIdSuccess: fetchByIdSuccessReducer<T>(entityAdapter),

  // Update
  update: updateReducer<T, { entity: T }>(),
  updateError: errorReducer<T>(),
  updateSuccess: updateSuccessReducer<T>(entityAdapter),

  // Replace an existing object by id
  setOne: setReducer<T>(entityAdapter),

  // Duplicate
  duplicate: duplicateReducer<T, { id: EntityId }>(),
  duplicateError: errorReducer<T>(),
  duplicateSuccess: duplicateSuccessReducer<T>(entityAdapter),
});

export const createSearchReducers = <T>(entityAdapter: EntityAdapter<T>) => ({
  cleanSearch: cleanSearchReducer<T>(),
  search: searchReducer<T>(),
  searchError: errorReducer<T>(),
  searchSuccess: searchSuccessReducer<T>(entityAdapter),
});

// Create reducers
export const createReducer =
  <T, U>() =>
  (state: EntityState<T> & BaseState, _action: PayloadAction<U>) =>
    updatePendingState(state, "CREATE");

export const createSuccessReducer =
  <T>(entityAdapter: EntityAdapter<T>) =>
  (
    state: EntityState<T> & BaseState,
    { payload }: PayloadAction<{ entity: T }>
  ) => {
    entityAdapter.upsertOne(state, payload.entity);
    updateSuccessState(state, "CREATE");
  };

// Error reducer
export const errorReducer =
  <T>() =>
  (
    state: EntityState<T> & BaseState,
    { payload }: PayloadAction<{ error: string }>
  ) =>
    updateErrorState(state, payload.error);

// Fetch all reducers
export const fetchAllReducer =
  <T>() =>
  (state: EntityState<T> & BaseState) =>
    updatePendingState(state, "FETCH_ALL");

export const fetchAllSuccessReducer =
  <T>(entityAdapter: EntityAdapter<T>) =>
  (
    state: EntityState<T> & BaseState,
    { payload }: PayloadAction<{ entities: T[] }>
  ) => {
    entityAdapter.setAll(state, payload.entities);
    updateSuccessState(state, "FETCH_ALL");
  };

// Fetch by id reducers
export const fetchByIdReducer =
  <T>() =>
  (
    state: EntityState<T> & BaseState,
    _action: PayloadAction<{ id: EntityId }>
  ) =>
    updatePendingState(state, "FETCH_BY_ID");

export const fetchByIdSuccessReducer =
  <T>(entityAdapter: EntityAdapter<T>) =>
  (
    state: EntityState<T> & BaseState,
    { payload }: PayloadAction<{ entity: T }>
  ) => {
    entityAdapter.upsertOne(state, payload.entity);
    updateSuccessState(state, "FETCH_BY_ID");
  };

// Update reducers
export const updateReducer =
  <T, U>() =>
  (state: EntityState<T> & BaseState, _action: PayloadAction<U>) =>
    updatePendingState(state, "UPDATE");

export const updateSuccessReducer =
  <T>(entityAdapter: EntityAdapter<T>) =>
  (
    state: EntityState<T> & BaseState,
    { payload }: PayloadAction<{ entity: T & BaseEntity }>
  ) => {
    entityAdapter.updateOne(state, {
      changes: payload.entity,
      id: payload.entity.id,
    });
    updateSuccessState(state, "UPDATE");
  };

// Set reducers
export const setReducer =
  <T>(entityAdapter: EntityAdapter<T>) =>
  (
    state: EntityState<T> & BaseState,
    { payload }: PayloadAction<{ entity: T & BaseEntity }>
  ) => {
    entityAdapter.setOne(state, {
      payload: payload.entity,
      type: "UPDATE",
    });
    updateSuccessState(state, "UPDATE");
  };

// Duplicate reducers
export const duplicateReducer =
  <T, U>() =>
  (state: EntityState<T> & BaseState, _action: PayloadAction<U>) =>
    updatePendingState(state, "DUPLICATE");

export const duplicateSuccessReducer =
  <T>(entityAdapter: EntityAdapter<T>) =>
  (
    state: EntityState<T> & BaseState,
    { payload }: PayloadAction<{ entity: T }>
  ) => {
    entityAdapter.upsertOne(state, payload.entity);
    updateSuccessState(state, "DUPLICATE");
  };

// Search reducers
export const cleanSearchReducer =
  <T>() =>
  (state: EntityState<T> & BaseState & SearchState) => {
    state.searchResultsIds = null;
  };

export const searchReducer =
  <T>() =>
  (
    state: EntityState<T> & BaseState & SearchState,
    _action: PayloadAction<{ filter?: string; offset?: number }>
  ) =>
    updatePendingState(state, "SEARCH");

export const searchSuccessReducer =
  <T>(entityAdapter: EntityAdapter<T>) =>
  (
    state: EntityState<T> & BaseState & SearchState,
    { payload }: PayloadAction<{ count?: number; entities: (T & BaseEntity)[] }>
  ) => {
    state.searchResultsIds = payload.entities.map((entity) => entity.id);

    if (payload.count !== undefined) {
      state.count = payload.count;
    }

    entityAdapter.upsertMany(state, payload.entities);
    updateSuccessState(state, "SEARCH");
  };
