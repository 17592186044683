import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";
// Utils
import { createBaseReducers, createSearchReducers } from "store/utils/reducers";
import { updateSuccessState } from "store/utils/stateUpdater";
// Types
import PointOfSale from "types/entities/pointOfSale";
// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

export const pointsOfSalesAdapter = createEntityAdapter<PointOfSale>();

export const pointsOfSalesSlice = createSlice({
  name: SLICE_NAME,
  initialState: pointsOfSalesAdapter.getInitialState(INITIAL_STATE),
  reducers: {
    ...createBaseReducers<PointOfSale>(pointsOfSalesAdapter),
    ...createSearchReducers<PointOfSale>(pointsOfSalesAdapter),
    fetchAllSuccess: (
      state,
      { payload }: PayloadAction<{ count: number; entities: PointOfSale[] }>
    ) => {
      state.count = payload.count;

      pointsOfSalesAdapter.setAll(state, payload.entities);
      updateSuccessState(state);
    },
  },
  extraReducers: {
    [LOCATION_CHANGE]: (state) => {
      state.searchResultsIds = null;
    },
  },
});

export default pointsOfSalesSlice.reducer;
