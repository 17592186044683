import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as posMachinesApi from "api/posMachines/posMachinesApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { posMachinesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import PosMachine from "types/entities/posMachine";

const fetchByIdSuccessHandler = ({ data: entity }: FetchResponse<PosMachine>) =>
  of(
    posMachinesSlice.actions.fetchByIdSuccess({
      entity,
    })
  );

const fetchByIdErrorHandler = (error: Error) =>
  of(
    posMachinesSlice.actions.fetchByIdError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la récupération de la tablette de caisse.",
    })
  );

const fetchByIdEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof posMachinesSlice.actions.fetchById>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(posMachinesSlice.actions.fetchById.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(posMachinesApi.fetchById({ id: payload.id, token })).pipe(
        switchMap(fetchByIdSuccessHandler),
        catchError(fetchByIdErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default fetchByIdEpic;
