import { EntityId } from "@reduxjs/toolkit";

// Constants
import { FETCH_USERS_ENDPOINT, GET_CURRENT_USER_ENDPOINT } from "./constants";

// Types
import TransactionsByUser from "types/entities/transactionsByUser";
import User from "types/entities/user";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";

const baseUrl = getApiUrl(FETCH_USERS_ENDPOINT);

export const fetchAll = (params: { token: string }) =>
  fetch<User[]>(baseUrl, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { id: EntityId; token: string }) =>
  fetch<User>(`${baseUrl}/${params.id}`, {
    method: "GET",
    token: params.token,
  });

export const fetchTransactionsByUserId = (params: {
  id: EntityId;
  token: string;
}) =>
  fetch<TransactionsByUser>(`${baseUrl}/${params.id}/transactions`, {
    method: "GET",
    token: params.token,
  });

export const getCurrentUser = (params: { token: string }) =>
  fetch<User>(getApiUrl(GET_CURRENT_USER_ENDPOINT), {
    method: "GET",
    token: params.token,
  });

export const search = (params: {
  filter?: string;
  offset?: number;
  token: string;
}) => {
  const searchParams = new URLSearchParams();

  if (params.filter) {
    searchParams.append("filter", params.filter);
  }

  if (params.offset) {
    searchParams.append("offset", String(params.offset));
  }

  return fetch<User[]>(`${baseUrl}?${searchParams.toString()}`, {
    method: "GET",
    token: params.token,
  });
};

export const update = (params: { entity: User; token: string }) =>
  fetch<User>(`${baseUrl}/${params.entity.id}`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });
