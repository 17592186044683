import React, { FC } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

// Components
import Logo from "components/Logo/Logo";
import Nav from "components/Nav/Nav";
import UserNav from "components/UserNav/UserNav";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Utils
import getRoutePath from "modules/routing/getRoutePath";

const PageHeader: FC = () => (
  <Navbar bg="primary" className="py-3" expand="lg" fixed="top" variant="dark">
    <Container className="d-flex align-items-center">
      <Link title="Home" to={getRoutePath(ROUTES_NAME.HOME)}>
        <Logo className="mr-3" height="60" />
      </Link>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav />
        <UserNav />
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default PageHeader;
