// APIs
import * as boxApi from "api/box/boxApi";
// Types
import { FetchResponse } from "modules/api/types";
import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";
// Slices
import { notificationSlice } from "store/features/notification/slice";
import Box from "types/entities/box";
import { ActionType, isOfType } from "typesafe-actions";
import { collectorsSlice } from "../slice";

const fetchAllSuccessHandler = ({
  data: { count, results },
}: FetchResponse<{ count: number; results: Box[] }>) =>
  of(
    collectorsSlice.actions.fetchAllSuccess({
      count,
      entities: results,
    })
  );

const fetchAllErrorHandler = (error: Error) =>
  of(
    collectorsSlice.actions.fetchAllError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la récupération des collecteurs.",
    })
  );

const fetchAllEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof collectorsSlice.actions.fetchAll>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(collectorsSlice.actions.fetchAll.type)),
    switchMap(() => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(boxApi.fetchAll({ token })).pipe(
        switchMap(fetchAllSuccessHandler),
        catchError(fetchAllErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default fetchAllEpic;
