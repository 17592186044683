import React, { FC } from "react";
import { Link } from "react-router-dom";

// Constants
import { ROUTES_NAME, ROUTES_PATH } from "modules/routing/constants";
import Logo from "components/Logo/Logo";

const NotFoundPage: FC = () => (
  <div className="d-flex align-items-center h-100 justify-content-center">
    <div className="text-center mt-n5 ">
      <Logo className="mb-4" height="120" />
      <div className="display-1">404</div>
      <div className="mb-4 lead">La page demandée est introuvable.</div>
      <Link
        to={ROUTES_PATH[ROUTES_NAME.HOME]}
        className="btn btn-link"
        title="Retourner sur la page d'accueil"
      >
        Retourner sur la page d'accueil
      </Link>
    </div>
  </div>
);

export default NotFoundPage;
