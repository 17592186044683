import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as transactionsInApi from "api/transactionsIn/transactionsInApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { transactionsByUserSlice } from "store/features/transactionsByUser/slice";
import { transactionsInSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import TransactionIn from "types/entities/transactionIn";

const createSuccessHandler = ({
  data: { transaction: entity },
}: FetchResponse<{
  transaction: TransactionIn;
}>) =>
  of(
    transactionsInSlice.actions.createSuccess({
      entity,
    }),
    notificationSlice.actions.addSuccessNotification({
      message: "La transaction entrante a été créée avec succès.",
      namespace: "transactionsIn",
    }),
    transactionsByUserSlice.actions.fetchAll({
      userId: entity.userId,
    })
  );

const createErrorHandler = (error: Error) =>
  of(
    transactionsInSlice.actions.createError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la création de la transaction entrante.",
      namespace: "transactionsIn",
    })
  );

const createEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof transactionsInSlice.actions.create>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(transactionsInSlice.actions.create.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        transactionsInApi.create({ entity: payload.entity, token })
      ).pipe(switchMap(createSuccessHandler), catchError(createErrorHandler));
    })
  );

export default createEpic;
