// Types
import { SearchState } from "store/types";
import { InitialState } from "./types";

export const SLICE_NAME = "sites";

export const INITIAL_STATE: InitialState & SearchState = {
  error: null,
  searchResultsIds: null,
  status: "INITIAL",
  statusAction: null,
};
