import { push } from "connected-react-router";
import { ActionsObservable } from "redux-observable";
import { of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Slices
import { currentUserSlice } from "store/features/currentUser/slice";

// Utils
import getRoutePath from "modules/routing/getRoutePath";
import { notificationSlice } from "store/features/notification/slice";

const logoutEpic = (
  action$: ActionsObservable<ActionType<typeof currentUserSlice.actions.logout>>
) =>
  action$.pipe(
    filter(isOfType(currentUserSlice.actions.logout.type)),
    switchMap(() =>
      of(
        push(getRoutePath(ROUTES_NAME.LOGIN)),
        notificationSlice.actions.addSuccessNotification({
          message: "Vous avez été déconnecté",
        })
      )
    )
  );

export default logoutEpic;
