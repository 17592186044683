import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import PosMachine from "types/entities/posMachine";

// Utils
import { createBaseReducers } from "store/utils/reducers";

export const posMachinesAdapter = createEntityAdapter<PosMachine>();

export const posMachinesSlice = createSlice({
  name: SLICE_NAME,
  initialState: posMachinesAdapter.getInitialState(INITIAL_STATE),
  reducers: createBaseReducers<PosMachine>(posMachinesAdapter),
});

export default posMachinesSlice.reducer;
