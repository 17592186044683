import { ActionsObservable, Epic } from "redux-observable";
import { push } from "connected-react-router";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as restaurantsApi from "api/restaurants/restaurantsApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { restaurantsSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import Restaurant from "types/entities/restaurant";

// Utils
import getEntityRoutePath from "modules/routing/getEntityRoutePath";

const updateSuccessHandler = ({ data: entity }: FetchResponse<Restaurant>) =>
  of(
    restaurantsSlice.actions.updateSuccess({
      entity,
    }),
    push(getEntityRoutePath(entity.id, ROUTES_NAME.RESTAURANTS_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "Le restaurant a été mis à jour avec succès.",
    })
  );

const updateErrorHandler = (error: Error) =>
  of(
    restaurantsSlice.actions.updateError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la mise à jour du restaurant.",
    })
  );

const updateEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof restaurantsSlice.actions.update>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(restaurantsSlice.actions.update.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        restaurantsApi.update({ entity: payload.entity, token })
      ).pipe(
        switchMap(updateSuccessHandler),
        catchError(updateErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default updateEpic;
