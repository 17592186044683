import { ActionsObservable, Epic } from "redux-observable";
import { push } from "connected-react-router";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as posMachinesApi from "api/posMachines/posMachinesApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { posMachinesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import PosMachine from "types/entities/posMachine";

// Utils
import getEntityRoutePath from "modules/routing/getEntityRoutePath";

const createSuccessHandler = ({
  data: posMachine,
}: FetchResponse<PosMachine>) =>
  of(
    posMachinesSlice.actions.createSuccess({
      entity: posMachine,
    }),
    posMachinesSlice.actions.fetchById({ id: posMachine.id }),
    push(getEntityRoutePath(posMachine.id, ROUTES_NAME.POS_MACHINES_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "La tablette de caisse a été créée avec succès.",
    })
  );

const createErrorHandler = (error: Error) =>
  of(
    posMachinesSlice.actions.createError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la création de la tablette de caisse.",
    })
  );

const createEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof posMachinesSlice.actions.create>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(posMachinesSlice.actions.create.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        posMachinesApi.create({ entity: payload.entity, token })
      ).pipe(
        switchMap(createSuccessHandler),
        catchError(createErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default createEpic;
