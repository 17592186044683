import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as itemTypesApi from "api/itemTypes/itemTypesApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { itemTypesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import ItemType from "types/entities/itemType";

const fetchByIdSuccessHandler = ({ data: entity }: FetchResponse<ItemType>) =>
  of(
    itemTypesSlice.actions.fetchByIdSuccess({
      entity,
    })
  );

const fetchByIdErrorHandler = (error: Error) =>
  of(
    itemTypesSlice.actions.fetchByIdError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la récupération du type de contenant.",
    })
  );

const fetchByIdEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof itemTypesSlice.actions.fetchById>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(itemTypesSlice.actions.fetchById.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(itemTypesApi.fetchById({ id: payload.id, token })).pipe(
        switchMap(fetchByIdSuccessHandler),
        catchError(fetchByIdErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default fetchByIdEpic;
