import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import ItemTypeCategory from "types/entities/itemTypeCategory";

// Utils
import { createBaseReducers } from "store/utils/reducers";

export const itemTypesCategoriesAdapter =
  createEntityAdapter<ItemTypeCategory>();

export const itemTypesCategoriesSlice = createSlice({
  name: SLICE_NAME,
  initialState: itemTypesCategoriesAdapter.getInitialState(INITIAL_STATE),
  reducers: createBaseReducers<ItemTypeCategory>(itemTypesCategoriesAdapter),
});

export default itemTypesCategoriesSlice.reducer;
