import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import {
  updateErrorState,
  updatePendingState,
  updateSuccessState,
} from "store/utils/stateUpdater";

export const appSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    initialize: (state) => updatePendingState(state),
    initializeError: (state, { payload }: PayloadAction<{ error: string }>) =>
      updateErrorState(state, payload.error),
    initializeSuccess: (state) => updateSuccessState(state),
  },
});

export default appSlice.reducer;
