import React, { FC } from "react";
import classNames from "classnames";
import Container from "react-bootstrap/Container";

// Components
import PageHeader from "components/PageHeader/PageHeader";

// Styles
import styles from "./Page.module.scss";

type Props = {
  className?: string;
  hasHeader?: boolean;
};

const Page: FC<Props> = ({ children, className, hasHeader = true }) => (
  <div
    className={classNames(
      {
        [styles.pageWithHeader]: hasHeader,
      },
      "h-100",
      className
    )}
  >
    {hasHeader && <PageHeader />}
    <Container className="py-4">{children}</Container>
  </div>
);

export default Page;
