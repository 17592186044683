// Constants
import { API_ENDPOINT } from "./constants";
// Types
import RecreditUsersResponse, {
  RecreditUsersRequest,
} from "types/entities/recreditUsers";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";
import cuid from "cuid";

const baseUrl = getApiUrl(API_ENDPOINT);

export const recreditUsers = (params: {
  entity: RecreditUsersRequest;
  token: string;
}) =>
  fetch<{ recreditUsers: RecreditUsersResponse }>(baseUrl, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
    requestId: cuid(),
  });
