import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as boxApi from "api/box/boxApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { collectorsSlice } from "../slice";

const emptySuccessHandler = () =>
  of(
    collectorsSlice.actions.emptySuccess(),
    notificationSlice.actions.addSuccessNotification({
      message: "Le collecteur a été vidé avec succès.",
    })
  );

const emptyErrorHandler = (error: Error) =>
  of(
    collectorsSlice.actions.emptyError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message: "Le collecteur n'a pas pu être vidé correctement.",
    })
  );

const emptyEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof collectorsSlice.actions.empty>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(collectorsSlice.actions.empty.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(boxApi.emptyById({ id: payload.id, token })).pipe(
        switchMap(emptySuccessHandler),
        catchError(emptyErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default emptyEpic;
