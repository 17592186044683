import { createSelector, EntityId } from "@reduxjs/toolkit";

// Store
import { RootState } from "store";

export const transactionsByUserStateSelector = (state: RootState) =>
  state.transactionsByUser;

export const transactionsByUserSelector = (userId: EntityId) =>
  createSelector(
    transactionsByUserStateSelector,
    (state) => state.entities[userId]
  );

export const isPendingSelector = createSelector(
  transactionsByUserStateSelector,
  ({ status }) => status === "PENDING"
);
