// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Types
import { RouteData } from "modules/routing/types";

// Utils
import lazyLoadPage from "./lazyLoadPage";

const ROUTES_DATA: RouteData[] = [
  {
    name: ROUTES_NAME.HOME,
    Component: lazyLoadPage("Home/HomePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.LOGIN,
    Component: lazyLoadPage("Login/LoginPage"),
    isRestricted: true,
  },
  // Sites
  {
    name: ROUTES_NAME.SITES,
    Component: lazyLoadPage("Sites/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.SITES_CREATE,
    Component: lazyLoadPage("Sites/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.SITES_READ,
    Component: lazyLoadPage("Sites/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.SITES_EDIT,
    Component: lazyLoadPage("Sites/EditPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.SITES_DUPLICATE,
    Component: lazyLoadPage("Sites/Duplicate"),
    isPrivate: true,
  },
  // Site networks
  {
    name: ROUTES_NAME.SITE_NETWORKS,
    Component: lazyLoadPage("SiteNetworks/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.SITE_NETWORKS_CREATE,
    Component: lazyLoadPage("SiteNetworks/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.SITE_NETWORKS_READ,
    Component: lazyLoadPage("SiteNetworks/ReadPage"),
    isPrivate: true,
  },
  // Games Configuration
  {
    name: ROUTES_NAME.GAMES_CONFIG,
    Component: lazyLoadPage("Sites/GamesConfig/GamesConfigPage"),
    isPrivate: true,
  },
  // Leasing Configuration
  {
    name: ROUTES_NAME.LEASING_AGREEMENT_CONFIGURATION,
    Component: lazyLoadPage("Sites/Leasing/LessorConfigurationPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.LEASING_DISTRIBUTION_CONFIGURATION,
    Component: lazyLoadPage("Sites/Distribution/DistributionConfigurationPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.LEASING_PAYMENT_CONFIGURATION,
    Component: lazyLoadPage("Sites/Payment/PaymentConfigurationPage"),
    isPrivate: true,
  },
  // Partner API access
  {
    name: ROUTES_NAME.PARTNER_API_ACCESS_SITE,
    Component: lazyLoadPage("Sites/PartnerApiAccess/PartnerApiAccessPage"),
    isPrivate: true,
  },
  // Client accesses
  {
    name: ROUTES_NAME.CLIENT_ACCESSES,
    Component: lazyLoadPage("ClientAccesses/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.CLIENT_ACCESSES_CREATE,
    Component: lazyLoadPage("ClientAccesses/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.CLIENT_ACCESSES_READ,
    Component: lazyLoadPage("ClientAccesses/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.CLIENT_ACCESSES_EDIT,
    Component: lazyLoadPage("ClientAccesses/EditPage"),
    isPrivate: true,
  },
  // Collectors
  {
    name: ROUTES_NAME.COLLECTORS,
    Component: lazyLoadPage("Collectors/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.COLLECTORS_CREATE,
    Component: lazyLoadPage("Collectors/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.COLLECTORS_READ,
    Component: lazyLoadPage("Collectors/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.COLLECTORS_EDIT,
    Component: lazyLoadPage("Collectors/EditPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.COLLECTORS_DUPLICATE,
    Component: lazyLoadPage("Collectors/Duplicate"),
    isPrivate: true,
  },
  // Collector types
  {
    name: ROUTES_NAME.COLLECTOR_TYPES,
    Component: lazyLoadPage("CollectorTypes/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.COLLECTOR_TYPES_CREATE,
    Component: lazyLoadPage("CollectorTypes/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.COLLECTOR_TYPES_READ,
    Component: lazyLoadPage("CollectorTypes/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.COLLECTOR_TYPES_EDIT,
    Component: lazyLoadPage("CollectorTypes/EditPage"),
    isPrivate: true,
  },
  // Items
  {
    name: ROUTES_NAME.ITEMS,
    Component: lazyLoadPage("Items/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.ITEMS_CREATE,
    Component: lazyLoadPage("Items/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.ITEMS_READ,
    Component: lazyLoadPage("Items/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.ITEMS_EDIT,
    Component: lazyLoadPage("Items/EditPage"),
    isPrivate: true,
  },
  // Item types
  {
    name: ROUTES_NAME.ITEM_TYPES,
    Component: lazyLoadPage("ItemTypes/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.ITEM_TYPES_CREATE,
    Component: lazyLoadPage("ItemTypes/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.ITEM_TYPES_READ,
    Component: lazyLoadPage("ItemTypes/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.ITEM_TYPES_EDIT,
    Component: lazyLoadPage("ItemTypes/EditPage"),
    isPrivate: true,
  },
  // Item categories
  {
    name: ROUTES_NAME.ITEM_CATEGORIES,
    Component: lazyLoadPage("ItemCategories/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.ITEM_CATEGORIES_CREATE,
    Component: lazyLoadPage("ItemCategories/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.ITEM_CATEGORIES_READ,
    Component: lazyLoadPage("ItemCategories/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.ITEM_CATEGORIES_EDIT,
    Component: lazyLoadPage("ItemCategories/EditPage"),
    isPrivate: true,
  },
  // User password reset
  {
    name: ROUTES_NAME.RESET_PASSWORD,
    Component: lazyLoadPage("ResetPassword/ResetPasswordPage"),
    isPrivate: true,
  },
  // Points of sales
  {
    name: ROUTES_NAME.POINTS_OF_SALES,
    Component: lazyLoadPage("PointsOfSales/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.POINTS_OF_SALES_CREATE,
    Component: lazyLoadPage("PointsOfSales/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.POINTS_OF_SALES_READ,
    Component: lazyLoadPage("PointsOfSales/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.POINTS_OF_SALES_EDIT,
    Component: lazyLoadPage("PointsOfSales/EditPage"),
    isPrivate: true,
  },
  // Pos machines
  {
    name: ROUTES_NAME.POS_MACHINES,
    Component: lazyLoadPage("PosMachines/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.POS_MACHINES_CREATE,
    Component: lazyLoadPage("PosMachines/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.POS_MACHINES_READ,
    Component: lazyLoadPage("PosMachines/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.POS_MACHINES_EDIT,
    Component: lazyLoadPage("PosMachines/EditPage"),
    isPrivate: true,
  },
  // Restaurant owners
  {
    name: ROUTES_NAME.RESTAURATEURS,
    Component: lazyLoadPage("Restaurateurs/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.RESTAURATEURS_CREATE,
    Component: lazyLoadPage("Restaurateurs/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.RESTAURATEURS_READ,
    Component: lazyLoadPage("Restaurateurs/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.RESTAURATEURS_EDIT,
    Component: lazyLoadPage("Restaurateurs/EditPage"),
    isPrivate: true,
  },
  // Restaurants
  {
    name: ROUTES_NAME.RESTAURANTS,
    Component: lazyLoadPage("Restaurants/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.RESTAURANTS_CREATE,
    Component: lazyLoadPage("Restaurants/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.RESTAURANTS_READ,
    Component: lazyLoadPage("Restaurants/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.RESTAURANTS_EDIT,
    Component: lazyLoadPage("Restaurants/EditPage"),
    isPrivate: true,
  },
  // Tech providers
  {
    name: ROUTES_NAME.TECH_PROVIDERS,
    Component: lazyLoadPage("TechProviders/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.TECH_PROVIDERS_CREATE,
    Component: lazyLoadPage("TechProviders/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.TECH_PROVIDERS_READ,
    Component: lazyLoadPage("TechProviders/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.TECH_PROVIDERS_EDIT,
    Component: lazyLoadPage("TechProviders/EditPage"),
    isPrivate: true,
  },
  // Partners
  {
    name: ROUTES_NAME.PARTNERS,
    Component: lazyLoadPage("Partners/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.PARTNERS_CREATE,
    Component: lazyLoadPage("Partners/CreatePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.PARTNERS_READ,
    Component: lazyLoadPage("Partners/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.PARTNERS_EDIT,
    Component: lazyLoadPage("Partners/EditPage"),
    isPrivate: true,
  },
  // Users
  {
    name: ROUTES_NAME.USERS,
    Component: lazyLoadPage("Users/BasePage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.USERS_READ,
    Component: lazyLoadPage("Users/ReadPage"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.USERS_EDIT,
    Component: lazyLoadPage("Users/EditPage"),
    isPrivate: true,
  },
  // RecreditUsers
  {
    name: ROUTES_NAME.USER_RECREDIT,
    Component: lazyLoadPage("Recredit/Recredit"),
    isPrivate: true,
  },
  {
    name: ROUTES_NAME.RETURN_MANAGEMENT,
    Component: lazyLoadPage("Sites/ReturnManagement/ReturnManagementPage"),
    isPrivate: true,
  },
];

export default ROUTES_DATA;
