import { ActionsObservable, Epic } from "redux-observable";
import { push } from "connected-react-router";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as itemTypesApi from "api/itemTypes/itemTypesApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { itemTypesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import ItemType from "types/entities/itemType";

// Utils
import getEntityRoutePath from "modules/routing/getEntityRoutePath";

const updateSuccessHandler = ({ data: entity }: FetchResponse<ItemType>) =>
  of(
    itemTypesSlice.actions.updateSuccess({
      entity,
    }),
    itemTypesSlice.actions.fetchById({ id: entity.id }),
    push(getEntityRoutePath(entity.id, ROUTES_NAME.ITEM_TYPES_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "Le type de contenant a été mis à jour avec succès.",
    })
  );

const updateErrorHandler = (error: Error) =>
  of(
    itemTypesSlice.actions.updateError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la mise à jour du type de contenant.",
    })
  );

const updateEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof itemTypesSlice.actions.update>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(itemTypesSlice.actions.update.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(itemTypesApi.update({ entity: payload.entity, token })).pipe(
        switchMap(updateSuccessHandler),
        catchError(updateErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default updateEpic;
