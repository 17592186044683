import React, { FC } from "react";

// Components
import Loader from "components/Loader/Loader";
import Page from "components/Page/Page";

const LoadingPage: FC = () => (
  <Page className="d-flex align-items-center" hasHeader={false}>
    <Loader />
  </Page>
);

export default LoadingPage;
