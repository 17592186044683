import { useSelector } from "react-redux";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Utils
import getTokenRemainingTime from "./getTokenRemainingTime";

const useToken = (): {
  isTokenValid: boolean;
  token: Nullable<string>;
} => {
  const token = useSelector(currentUserTokenSelector);
  const tokenRemainingTime = token && getTokenRemainingTime(token);

  return {
    isTokenValid: tokenRemainingTime ? tokenRemainingTime > 15 * 60 : false,
    token,
  };
};

export default useToken;
