const {
  REACT_APP_RESTAURATEUR_API_BASE_URL,
  REACT_APP_MOCK_API_BASE_URL,
} = process.env;

type Params = {
  useMockApi?: boolean;
};

const getRestaurateurApiUrl = (apiEndpoint: string, params: Params = {}) => {
  const baseUrl = params.useMockApi
    ? REACT_APP_MOCK_API_BASE_URL
    : REACT_APP_RESTAURATEUR_API_BASE_URL;

  return `${baseUrl}/${apiEndpoint}`;
};

export default getRestaurateurApiUrl;
