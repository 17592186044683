import { ActionsObservable, Epic } from "redux-observable";
import { push } from "connected-react-router";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as usersApi from "api/users/usersApi";

// Constants
import { ROUTES_NAME } from "modules/routing/constants";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { usersSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import User from "types/entities/user";

// Utils
import getEntityRoutePath from "modules/routing/getEntityRoutePath";

const updateSuccessHandler = ({ data: entity }: FetchResponse<User>) =>
  of(
    usersSlice.actions.updateSuccess({
      entity,
    }),
    push(getEntityRoutePath(entity.id, ROUTES_NAME.USERS_READ)),
    notificationSlice.actions.addSuccessNotification({
      message: "L'utilisateur a été mis à jour avec succès.",
      namespace: "users",
    })
  );

const updateErrorHandler = (error: Error) =>
  of(
    usersSlice.actions.updateError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la mise à jour de l'utilisateur.",
      namespace: "users",
    })
  );

const updateEpic: Epic = (
  action$: ActionsObservable<ActionType<typeof usersSlice.actions.update>>,
  state$
) =>
  action$.pipe(
    filter(isOfType(usersSlice.actions.update.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(usersApi.update({ entity: payload.entity, token })).pipe(
        switchMap(updateSuccessHandler),
        catchError(updateErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default updateEpic;
