import { combineEpics } from "redux-observable";

// Epics
import appEpic from "./features/app/epic";
import sitesEpic from "./features/sites/epic";
import clientAccessesEpic from "./features/clientAccesses/epic";
import collectorsEpic from "./features/collectors/epic";
import collectorTypesEpic from "./features/collectorTypes/epic";
import currentUserEpic from "./features/currentUser/epic";
import itemsEpic from "./features/items/epic";
import itemTypesEpic from "./features/itemTypes/epic";
import lastTransactionsInEpic from "./features/lastTransactionsIn/epic";
import lastTransactionsOutEpic from "./features/lastTransactionsOut/epic";
import pointsOfSalesEpic from "./features/pointsOfSales/epic";
import posMachinesEpic from "./features/posMachines/epic";
import restaurantOwnersEpic from "./features/restaurateurs/epic";
import restaurantsEpic from "./features/restaurants/epic";
import servicesEpic from "./features/services/epic";
import techProvidersEpic from "./features/techProviders/epic";
import transactionsByUserEpic from "./features/transactionsByUser/epic";
import transactionsInEpic from "./features/transactionsIn/epic";
import transactionsOutEpic from "./features/transactionsOut/epic";
import usersEpic from "./features/users/epic";
import itemTypesCategoriesEpic from "./features/itemTypesCategories/epic";
import recreditUserEpic from "./features/recreditUsers/epic";
import boxV3ConfigurationsEpic from "./features/boxesV3Configurations/epic";

const rootEpic = combineEpics(
  appEpic,
  sitesEpic,
  clientAccessesEpic,
  collectorsEpic,
  collectorTypesEpic,
  currentUserEpic,
  itemsEpic,
  itemTypesEpic,
  lastTransactionsInEpic,
  lastTransactionsOutEpic,
  pointsOfSalesEpic,
  posMachinesEpic,
  restaurantOwnersEpic,
  restaurantsEpic,
  servicesEpic,
  techProvidersEpic,
  transactionsByUserEpic,
  transactionsInEpic,
  transactionsOutEpic,
  itemTypesCategoriesEpic,
  usersEpic,
  recreditUserEpic,
  boxV3ConfigurationsEpic
);

export default rootEpic;
