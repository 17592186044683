import React, { FC, useState } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useSelector } from "react-redux";

// Components
import LogoutModal from "components/LogoutModal/LogoutModal";

// Selectors
import { currentUserNameSelector } from "store/features/currentUser/selectors";

const UserNav: FC = () => {
  const currentUserName = useSelector(currentUserNameSelector);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  const onSelect = (eventKey: Nullable<string>) => {
    if (eventKey === "logout") {
      setIsLogoutModalOpen(true);
    }
  };

  if (!currentUserName) {
    return null;
  }

  return (
    <>
      <Nav onSelect={onSelect}>
        <NavDropdown id="user-nav" title={currentUserName}>
          <NavDropdown.Item eventKey="logout">Logout</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <LogoutModal isOpen={isLogoutModalOpen} onHide={closeLogoutModal} />
    </>
  );
};

export default UserNav;
