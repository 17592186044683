import { EntityId } from "@reduxjs/toolkit";
import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as transactionsOutApi from "api/transactionsOut/transactionsOutApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { transactionsByUserSlice } from "store/features/transactionsByUser/slice";
import { transactionsOutSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import TransactionOut from "types/entities/transactionOut";

const createSuccessHandler = (userId: EntityId) => ({
  data: { transaction: entity },
}: FetchResponse<{
  transaction: TransactionOut;
}>) =>
  of(
    transactionsOutSlice.actions.createSuccess({
      entity,
    }),
    notificationSlice.actions.addSuccessNotification({
      message: "La transaction sortante a été créée avec succès.",
      namespace: "transactionsOut",
    }),
    transactionsByUserSlice.actions.fetchAll({
      userId,
    })
  );

const createErrorHandler = (error: Error) =>
  of(
    transactionsOutSlice.actions.createError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la création de la transaction sortante.",
      namespace: "transactionsOut",
    })
  );

const createEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof transactionsOutSlice.actions.create>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(transactionsOutSlice.actions.create.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        transactionsOutApi.create({ entity: payload.entity, token })
      ).pipe(
        switchMap(createSuccessHandler(payload.entity.userId)),
        catchError(createErrorHandler)
      );
    })
  );

export default createEpic;
