import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

//Components
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import PublicRoute from "components/PublicRoute/PublicRoute";

// Constants
import { DEFAULT_ROUTE_NAME } from "modules/routing/constants";
import ROUTES_DATA from "modules/routing/routesData";

// Pages
import LoadingPage from "pages/Loading/LoadingPage";
import NotFoundPage from "pages/NotFound/NotFoundPage";

// Store
import { history } from "store";

// Utils
import getRoutePath from "modules/routing/getRoutePath";

const Router = () => (
  <ConnectedRouter history={history}>
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <Redirect exact from="/" to={getRoutePath(DEFAULT_ROUTE_NAME)} />
        {ROUTES_DATA.map((routeData) => {
          const { Component, isPrivate, name } = routeData;
          const RouteComponent = isPrivate ? PrivateRoute : PublicRoute;

          return (
            <RouteComponent
              {...routeData}
              key={name}
              exact
              path={getRoutePath(name)}
            >
              <Component />
            </RouteComponent>
          );
        })}
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Suspense>
  </ConnectedRouter>
);

export default Router;
