import { createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";

// Constants
import { INITIAL_STATE, SLICE_NAME } from "./constants";

// Types
import transactionsByUser from "types/entities/transactionsByUser";

// Utils
import {
  updateErrorState,
  updatePendingState,
  updateSuccessState,
} from "store/utils/stateUpdater";

export const transactionsByUserSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    fetchAll: (
      state,
      _action: PayloadAction<{
        userId: EntityId;
      }>
    ) => {
      updatePendingState(state, "FETCH_ALL");
    },
    fetchAllError: (
      state,
      {
        payload,
      }: PayloadAction<{
        error: string;
      }>
    ) => {
      updateErrorState(state, payload.error);
    },
    fetchAllSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        userId: EntityId;
        transactions: transactionsByUser;
      }>
    ) => {
      state.entities[payload.userId] = payload.transactions;
      updateSuccessState(state, "FETCH_ALL");
    },
  },
});

export default transactionsByUserSlice.reducer;
