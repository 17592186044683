import { EntityId } from "@reduxjs/toolkit";

// Constants
import { API_ENDPOINT } from "./constants";

// Types
import CollectorType from "types/entities/collectorType";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";

const baseUrl = getApiUrl(API_ENDPOINT);

export const create = (params: { entity: CollectorType; token: string }) =>
  fetch<CollectorType>(`${baseUrl}/new`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });

export const fetchAll = (params: { token: string }) =>
  fetch<CollectorType[]>(baseUrl, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { id: EntityId; token: string }) =>
  fetch<CollectorType>(`${baseUrl}/${params.id}`, {
    method: "GET",
    token: params.token,
  });

export const update = (params: { entity: CollectorType; token: string }) =>
  fetch<CollectorType>(`${baseUrl}/${params.entity.id}`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });

export const duplicate = (params: { id: EntityId; token: string }) =>
  fetch<CollectorType>(
    `${baseUrl.replace("collector_types", "box")}/duplicate/${params.id}`,
    {
      method: "POST",
      token: params.token,
    }
  );
