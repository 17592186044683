import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as restaurantsApi from "api/restaurants/restaurantsApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { restaurantsSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import Restaurant from "types/entities/restaurant";

const fetchByIdSuccessHandler = ({ data: entity }: FetchResponse<Restaurant>) =>
  of(
    restaurantsSlice.actions.fetchByIdSuccess({
      entity,
    })
  );

const fetchByIdErrorHandler = (error: Error) =>
  of(
    restaurantsSlice.actions.fetchByIdError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la récupération du restaurant.",
    })
  );

const fetchByIdEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof restaurantsSlice.actions.fetchById>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(restaurantsSlice.actions.fetchById.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(restaurantsApi.fetchById({ id: payload.id, token })).pipe(
        switchMap(fetchByIdSuccessHandler),
        catchError(fetchByIdErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default fetchByIdEpic;
