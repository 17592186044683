import { EntityId } from "@reduxjs/toolkit";

// Constants
import { API_ENDPOINT } from "./constants";

// Types
import PosMachine from "types/entities/posMachine";

// Utils
import fetch from "modules/api/fetch";
import getApiUrl from "modules/api/getApiUrl";
import {
  normalizedPosMachineForUpdate,
  PosMachineCreation,
} from "components/PosMachines/normalizer";

const baseUrl = getApiUrl(API_ENDPOINT);

export const create = (params: { entity: PosMachineCreation; token: string }) =>
  fetch<PosMachine>(`${baseUrl}/new`, {
    body: JSON.stringify(params.entity),
    method: "POST",
    token: params.token,
  });

export const fetchAll = (params: { token: string }) =>
  fetch<PosMachine[]>(baseUrl, {
    method: "GET",
    token: params.token,
  });

export const fetchById = (params: { id: EntityId; token: string }) =>
  fetch<PosMachine>(`${baseUrl}/${params.id}`, {
    method: "GET",
    token: params.token,
  });

export const update = (params: { entity: PosMachine; token: string }) => {
  const entity = normalizedPosMachineForUpdate(params.entity);
  return fetch<PosMachine>(`${baseUrl}/${params.entity.id}`, {
    body: JSON.stringify(entity),
    method: "POST",
    token: params.token,
  });
};
