import { isServerError } from "api/types";
import { hasOwnProperty } from "./asserts";

export function generateErrorMessage(error: unknown): string {
  if (
    error instanceof Object &&
    hasOwnProperty(error, "error") &&
    error.error instanceof Object &&
    hasOwnProperty(error.error, "data") &&
    isServerError(error.error.data)
  ) {
    return `[errorCode: ${error.error.data.error}] : ${error.error.data.message}`;
  } else {
    console.error("Error format not recognized", error);
    return "Error format not recognized, check the console logs for more details.";
  }
}
