// Types
import { InitialState } from "./types";

export const SLICE_NAME = "notification";

export const INITIAL_STATE: InitialState = {
  message: null,
  shouldCleanOnRouteChange: true,
  title: null,
  type: null,
};
